import React, { useState } from "react";
import { Col, Row, Card, FormGroup, Label, Button } from "reactstrap";
import { Link } from "react-router-dom";
import {
  AvForm,
  AvInput,
  AvCheckboxGroup,
  AvCheckbox,
} from "availity-reactstrap-validation-safe";
import Select from "react-select";
import InputPasswordToggle from "../../components/input-password-toggle/index";
import PlaceHolder from "../../assets/images/placeholder-image.jpg";
import styled from "styled-components";

export const Register = () => {
  const [errors, setErrors] = useState({});
  const [email, setEmail] = useState("");
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [organization_name, setOrganizationName] = useState("");
  const [requestAccess, setRequestAccess] = useState([]);
  const [password, setPassword] = useState("");

  const valInitiatives = [
    { value: "CAN", label: "CAN" },
    { value: "COPA", label: "COPA" },
    { value: "CPAN", label: "CPAN" },
    { value: "COA", label: "COA" },
  ];

  const handleSubmit = (event, errors, values) => {};

  const handleFirstName = (e) => {
    const errs = errors;
    if (errs.first_name) delete errs.first_name;
    setFirstName(e.target.value);
    setErrors(errs);
  };

  const handleLastName = (e) => {
    const errs = errors;
    if (errs.last_name) delete errs.last_name;
    setLastName(e.target.value);
    setErrors(errs);
  };

  const handlePhone = (e) => {
    const errs = errors;
    if (errs.phone) delete errs.phone;
    setPhone(e.target.value);
    setErrors(errs);
  };

  const handleZipCode = (e) => {
    const errs = errors;
    if (errs.zipCode) delete errs.zipCode;
    setZipCode(e.target.value);
    setErrors(errs);
  };

  const handleEmail = (e) => {
    const errs = errors;
    if (errs.email) delete errs.email;
    setEmail(e.target.value);
    setErrors(errs);
  };

  const handlePassword = (e) => {
    const errs = errors;
    if (errs.password) delete errs.password;
    setPassword(e.target.value);
    setErrors(errs);
  };

  const handleOrganizationName = (e) => {
    const errs = errors;
    if (errs.organization_name) delete errs.organization_name;
    setOrganizationName(e.target.value);
    setErrors(errs);
  };

  const handlerequestAccess = (e) => {
    setRequestAccess(e);
  };

  const selectThemeColors = (theme) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: "#7367f01a", // for option hover bg-color
      primary: "#7367f0", // for selected option bg-color
      neutral10: "#04629C", // for tags bg-color
      neutral20: "#ededed", // for input border-color
      neutral30: "#ededed", // for input hover border-color
    },
  });

  return (
    <RegisterWrapper className="register-page p-3  p-sm-5">
      <Row className="">
        <Col
          xl="8"
          lg="7"
          sm="12"
          md="7"
          className="register-left pr-md-5 mb-3 mb-md-0"
        >
          <h2 className="mb-3 text-primary">Create a Free Account</h2>

          <h5 className="mb-3">Access to All Our Initiatives</h5>
          <Row className="mb-2 initiative-card-wrapper pb-3">
            <Col md="6" lg="3" className="mb-4">
              <Card>
                <img src={PlaceHolder} alt="placeholder" />
              </Card>
            </Col>
            <Col md="6" lg="3" className="mb-4">
              <Card>
                <img src={PlaceHolder} alt="placeholder" />
              </Card>
            </Col>
            <Col md="6" lg="3" className="mb-4">
              <Card>
                <img src={PlaceHolder} alt="placeholder" />
              </Card>
            </Col>
            <Col md="6" lg="3" className="mb-4">
              <Card>
                <img src={PlaceHolder} alt="placeholder" />
              </Card>
            </Col>
          </Row>

          <h5 className="mb-3">Videos, Downloadable, Listservers & more!</h5>
          <Row className="initiative-card-wrapper ">
            <Col md="6" lg="3" className="mb-4">
              <Card>
                <img src={PlaceHolder} alt="placeholder" />
              </Card>
            </Col>
            <Col md="6" lg="3" className="mb-4">
              <Card>
                <img src={PlaceHolder} alt="placeholder" />
              </Card>
            </Col>
            <Col md="6" lg="3" className="mb-4">
              <Card>
                <img src={PlaceHolder} alt="placeholder" />
              </Card>
            </Col>
            <Col md="6" lg="3" className="mb-4">
              <Card>
                <img src={PlaceHolder} alt="placeholder" />
              </Card>
            </Col>
          </Row>
        </Col>
        <Col
          className="d-flex align-items-center "
          xl="4"
          lg="5"
          md="5"
          sm="12"
        >
          <div className="w-100 register-right">
            <h4 className="mb-3" style={{ color: "#000", fontWeight: "600" }}>
              Sign up
            </h4>
            <AvForm
              action="/"
              className="auth-register-form mt-2"
              onSubmit={handleSubmit}
            >
              <FormGroup>
                <Label className="form-label" for="name">
                  First Name
                </Label>
                <AvInput
                  required
                  autoFocus
                  type="text"
                  placeholder="your first name"
                  id="first_name"
                  name="first_name"
                  value={first_name}
                  onChange={handleFirstName}
                />
              </FormGroup>
              <FormGroup>
                <Label className="form-label" for="last_name">
                  Last Name
                </Label>
                <AvInput
                  required
                  type="text"
                  placeholder="your last name"
                  id="last_name"
                  name="last_name"
                  value={last_name}
                  onChange={handleLastName}
                />
              </FormGroup>
              <FormGroup>
                <Label className="form-label" for="organization_name">
                  Organization Name
                </Label>
                <AvInput
                  required
                  type="text"
                  placeholder="your organization name"
                  id="organization_name"
                  name="organization_name"
                  value={organization_name}
                  onChange={handleOrganizationName}
                />
              </FormGroup>

              <FormGroup>
                <Label className="form-label" for="phone_number">
                  Phone Number
                </Label>
                <AvInput
                  required
                  type="text"
                  placeholder="your phone number"
                  id="phone_number"
                  name="phone_number"
                  value={phone}
                  onChange={handlePhone}
                />
              </FormGroup>
              <FormGroup>
                <Label className="form-label" for="zipcode">
                  Zip Code
                </Label>
                <AvInput
                  required
                  type="text"
                  placeholder="your zip Code"
                  id="zipCode"
                  name="zipCode"
                  value={zipCode}
                  onChange={handleZipCode}
                />
              </FormGroup>
              <FormGroup>
                <Label className="form-label" for="business-email">
                  Email
                </Label>
                <AvInput
                  required
                  type="email"
                  id="email"
                  name="email"
                  placeholder="your email address"
                  value={email}
                  onChange={handleEmail}
                />
              </FormGroup>
              <FormGroup>
                <Label className="form-label" for="register-password">
                  Password
                </Label>
                <InputPasswordToggle
                  required
                  tag={AvInput}
                  id="password"
                  name="password"
                  value={password}
                  onChange={handlePassword}
                />
                <small className="text-muted">
                  Use 8 or more characters with a mix of letters, numbers and
                  symbols.
                </small>
              </FormGroup>
              <FormGroup className="mb-4">
                <Label className="form-label" for="initiatives">
                  Request initiatives access
                </Label>
                <Select
                  isMulti
                  theme={selectThemeColors}
                  className="react-select mb-2"
                  classNamePrefix="select"
                  options={valInitiatives}
                  isClearable={false}
                  id={"initiatives"}
                  menuPlacement="top"
                  placeholder="Select all that apply"
                  onChange={handlerequestAccess}
                  style={{ height: "38px", borderColor: "#ced4da" }}
                />
              </FormGroup>

              <Button
                block
                color="secondary"
                // disabled={!email.length || !password.length || !first_name.length || !organization_name}
              >
                <h5
                  className="mb-0 text-light text-capitalize"
                  style={{ fontSize: "18px" }}
                >
                  Create Your Account
                </h5>
              </Button>
              <div
                className="text-center d-flex flex-wrap align-items-center justify-content-center mt-2"
                style={{ fontWeight: "500" }}
              >
                Already a member? &nbsp;{" "}
                <Link to="/login"> Login to MyCOA</Link>
              </div>
            </AvForm>

            <hr />
            <small className="text-center d-block">
              COA collects and uses personal data in accordance with our{" "}
              <Link to="#">Privacy Policy</Link>. By creating account, you agree
              to our <Link to="#">Terms and Conditions.</Link>
            </small>
          </div>
        </Col>
      </Row>
    </RegisterWrapper>
  );
};

export default Register;

export const RegisterWrapper = styled.div`
  /* background-image: linear-gradient(80deg, #a4b9ff 0%, #ffd4da 100%); */
  background-color: #fff;
  .register-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: -150px;

    .initiative-card-wrapper {
      .card {
        border: 1px solid #d2d2d2;
        box-shadow: unset;

        img {
          overflow: hidden;
          position: relative;
          border-radius: 0.428rem;
        }
      }
    }

    @media (min-width: 1200px) {
      padding-left: 150px !important;
    }

    h2 {
      font-size: 30px;
      color: var(--coa-primary);
      font-weight: 700;
      width: 100%;
    }

    h4 {
      color: #000;
      width: 100%;
    }

    @media (max-width: 992px) {
      margin-top: 0px;
    }
  }

  .register-right {
    background-color: #fff;
    padding: 20px 20px;
    border-radius: 0.42rem;
    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.2);

    a {
      text-decoration: underline;
    }
  }

  .circletop1 {
    position: absolute;
    width: 200px;
    top: 2vw;
    left: -6vw;
  }

  .circletop2 {
    position: absolute;
    width: 163px;
    left: 66%;
    top: 9%;
  }

  .circleright {
    position: absolute;
    right: -42px;
    width: 72px;
    top: 37%;
    transform: translateY(-50%);
  }

  .circlebottombottom {
    position: absolute;
    bottom: -3px;
    left: 65%;
    width: 84px;
  }

  .circlebottomleft {
    position: absolute;
    width: 120px;
    bottom: 11%;
    left: -51px;
  }

  @media (max-width: 992px) {
    .circletop1 {
      display: none;
    }
  }
`;

import React from "react";
import { ExternalLink } from "react-feather";
import { Button, Col, Container, Row } from "reactstrap";
import styled from "styled-components";

// images
import placeholderImage from "../../../assets/images/resource-placeholder.jpg";

const RightSidebar2 = () => {
  return (
    <PageTemplate id="page-wrapper" className="pb-6 pt-5 pt-lg-6">
      <Container>
        <Row>
          <Col lg="8" xl="9" className="mb-5 mb-lg-0">
            <h2 className="mb-0">Resource Title</h2>
            <hr />
            <div class="meta d-flex align-items-center flex-wrap">
              <div className="date mr-3">MM/DD/YYYY</div>
              <div className="category">CATEGORY:TAGS</div>
            </div>

            <div className="content mt-5">
              <p>
                Lorem ipsum dolor sit amet consectetur. Diam dolor diam erat
                fermentum. Et est purus sit eget lacus. Dictumst morbi hendrerit
                sed venenatis mauris lobortis orci eget nisl. Ac sollicitudin
                quis et amet venenatis. Aliquet mi a sem sit libero vitae
                adipiscing. Sit elit id duis nibh lacinia scelerisque ac nisl.
              </p>
              <p>
                Lorem ipsum dolor sit amet consectetur. Diam dolor diam erat
                fermentum. Et est purus sit eget lacus. Dictumst morbi hendrerit
                sed venenatis mauris lobortis orci eget nisl. Ac sollicitudin
                quis et amet venenatis. Aliquet mi a sem sit libero vitae
                adipiscing. Sit elit id duis nibh lacinia scelerisque ac nisl.
                Lorem ipsum dolor sit amet consectetur. Diam dolor diam erat
                fermentum. Et est purus sit eget lacus. Dictumst morbi hendrerit
                sed venenatis mauris lobortis orci eget nisl. Ac sollicitudin
                quis et amet venenatis. Aliquet mi a sem sit libero vitae
                adipiscing. Sit elit id duis nibh lacinia scelerisque ac nisl.
              </p>
              <p>
                Lorem ipsum dolor sit amet consectetur. Diam dolor diam erat
                fermentum. Et est purus sit eget lacus. Dictumst morbi hendrerit
                sed venenatis mauris lobortis orci eget nisl. Ac sollicitudin
                quis et amet venenatis. Aliquet mi a sem sit libero vitae
                adipiscing. Sit elit id duis nibh lacinia scelerisque ac nisl.
              </p>
              <p>
                Lorem ipsum dolor sit amet consectetur. Diam dolor diam erat
                fermentum. Et est purus sit eget lacus. Dictumst morbi hendrerit
                sed venenatis mauris lobortis orci eget nisl. Ac sollicitudin
                quis et amet venenatis. Aliquet mi a sem sit libero vitae
                adipiscing. Sit elit id duis nibh lacinia scelerisque ac nisl.
              </p>
              <p>
                Lorem ipsum dolor sit amet consectetur. Diam dolor diam erat
                fermentum. Et est purus sit eget lacus. Dictumst morbi hendrerit
                sed venenatis mauris lobortis orci eget nisl. Ac sollicitudin
                quis et amet venenatis. Aliquet mi a sem sit libero vitae
                adipiscing. Sit elit id duis nibh lacinia scelerisque ac
                nisl.Lorem ipsum dolor sit amet consectetur. Diam dolor diam
                erat fermentum. Et est purus sit eget lacus. Dictumst morbi
                hendrerit sed venenatis mauris lobortis orci eget nisl. Ac
                sollicitudin quis et amet venenatis. Aliquet mi a sem sit libero
                vitae adipiscing. Sit elit id duis nibh lacinia scelerisque ac
                nisl.
              </p>
              <p>
                Lorem ipsum dolor sit amet consectetur. Diam dolor diam erat
                fermentum. Et est purus sit eget lacus. Dictumst morbi hendrerit
                sed venenatis mauris lobortis orci eget nisl. Ac sollicitudin
                quis et amet venenatis. Aliquet mi a sem sit libero vitae
                adipiscing. Sit elit id duis nibh lacinia scelerisque ac
                nisl.Lorem ipsum dolor sit amet consectetur. Diam dolor diam
                erat fermentum. Et est purus sit eget lacus. Dictumst morbi
                hendrerit sed venenatis mauris lobortis orci eget nisl. Ac
                sollicitudin quis et amet venenatis. Aliquet mi a sem sit libero
                vitae adipiscing. Sit elit id duis nibh lacinia scelerisque ac
                nisl.
              </p>
            </div>
          </Col>
          <Col lg="4" xl="3">
            <SidebarWrapper>
              <Button
                color="secondary"
                size=""
                block
                tag="a"
                download
                outline
                className="mb-40"
              >
                DOWNLOAD PDF
              </Button>

              <div className="featured-image mb-40">
                <img
                  src={placeholderImage}
                  alt="featured image"
                  className="w-100 rounded"
                />
              </div>

              <div className="recommended-resources rounded">
                <h6 className="text-center">Recommended Resources</h6>
                <ul className="list-unstyled mb-0">
                  <li>
                    <a href="#" className="text-sm d-flex align-items-center">
                      <ExternalLink className="mr-2" size="16px" />
                      Full Test Resource Title Long{" "}
                    </a>
                  </li>

                  <li>
                    <a href="#" className="text-sm d-flex align-items-center">
                      <ExternalLink className="mr-2" size="16px" />
                      Full Test Resource Title Long{" "}
                    </a>
                  </li>

                  <li>
                    <a href="#" className="text-sm d-flex align-items-center">
                      <ExternalLink className="mr-2" size="16px" />
                      Full Test Resource Title Long{" "}
                    </a>
                  </li>

                  <li>
                    <a href="#" className="text-sm d-flex align-items-center">
                      <ExternalLink className="mr-2" size="16px" />
                      Full Test Resource Title Long{" "}
                    </a>
                  </li>

                  <li>
                    <a href="#" className="text-sm d-flex align-items-center">
                      <ExternalLink className="mr-2" size="16px" />
                      Full Test Resource Title Long{" "}
                    </a>
                  </li>

                  <li>
                    <a href="#" className="text-sm d-flex align-items-center">
                      <ExternalLink className="mr-2" size="16px" />
                      Full Test Resource Title Long{" "}
                    </a>
                  </li>

                  <li>
                    <a href="#" className="text-sm d-flex align-items-center">
                      <ExternalLink className="mr-2" size="16px" />
                      Full Test Resource Title Long{" "}
                    </a>
                  </li>
                </ul>
              </div>
            </SidebarWrapper>
          </Col>
        </Row>
      </Container>
    </PageTemplate>
  );
};

export default RightSidebar2;

export const PageTemplate = styled.section`
  background: linear-gradient(180deg, #d5e5ef 0%, rgba(213, 229, 239, 0) 40%);

  hr {
    color: #d1d3d4;
  }

  .date {
    color: #898a8d;
    font-family: var(--primaryFont);
    font-size: 12px;
    line-height: 18px;
    font-weight: 800;
    letter-spacing: 0.75px;
  }

  .category {
    color: #4180be;
    font-family: var(--primaryFont);
    font-size: 12px;
    line-height: 18px;
    font-weight: 800;
    letter-spacing: 0.75px;
  }

  .content {
    & > :last-child {
      margin-bottom: 0;
    }
  }
`;

export const SidebarWrapper = styled.aside`
  .mb-40 {
    margin-bottom: 40px;
  }

  .recommended-resources {
    background-color: #f3f3f3;
    padding: 25px;

    svg {
      stroke-width: 2.6px;
    }

    ul {
      li:not(:last-child) {
        margin-bottom: 40px;
      }
      a {
        text-decoration: underline;
      }
    }
  }

  h6 {
    margin-bottom: 23px;
  }
`;

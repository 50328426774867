import styled from "styled-components";

export const HeroSection = styled.section`
  h3 {
    font-size: 36px;
    font-weight: 800;
    line-height: 40px;
    letter-spacing: 0em;
  }

  a {
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;

    svg {
      stroke-width: 2.3px;
    }
  }
`;

export const CardSection = styled.section`
  position: relative;
  z-index: 3;

  &::after {
    content: "";
    background-image: url(${(props) => props.bgImage});

    position: absolute;
    width: 100%;
    height: 65%;
    background-repeat: no-repeat;
    bottom: 150px;
    background-size: cover;
    z-index: 1;
  }

  .card-warp {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    border-radius: 6px;
    position: relative;
    z-index: 3;

    .card {
      position: relative;

      background-color: #f3f3f3;

      border: unset;

      img {
        width: 100px;
        position: absolute;
        left: 50%;
        top: 50px;
        transform: translate(-50%, -100px);
      }

      h4 {
        font-family: "Montserrat", sans-serif;
        font-size: 22px;
        font-weight: 800;
        line-height: 28px;
        letter-spacing: 0px;
        margin-top: 50px;
      }

      hr {
        border-color: var(--link);
        max-width: 70%;
        margin-left: auto;
        margin-right: auto;
      }
    }

    @media (max-width: 992px) {
      grid-template-columns: repeat(2, 1fr);

      .card {
        &:nth-child(1),
        &:nth-child(2) {
          margin-bottom: 50px;
        }
      }
    }

    @media (max-width: 576px) {
      grid-template-columns: repeat(1, 1fr);

      .card {
        &:not(:last-child) {
          margin-bottom: 50px;
          margin-top: 0;
        }
      }
    }
  }

  .video-wrapper {
    position: relative;
    z-index: 3;
    video {
      aspect-ratio: 16/9;
    }
  }
`;

export const TwoColSection = styled.section`
  h3 {
    font-size: 32px;
    font-weight: 800;
    line-height: 40px;
  }
`;

export const VideoSection = styled.section`
  position: relative;

  &::after {
    content: "";
    background-image: url(${(props) => props.bgImage});

    position: absolute;
    width: 100%;
    height: 50%;
    background-repeat: no-repeat;
    bottom: 0px;
    background-size: cover;
    z-index: 1;
  }

  .video-wrapper {
    position: relative;
    z-index: 3;
    video {
      aspect-ratio: 16/9;
    }
  }
`;

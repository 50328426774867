import React from "react";
import { Facebook, Twitter, Linkedin, Mail } from "react-feather";
import { Button } from "reactstrap";
import styled from "styled-components";

const SocialShare = ({ mobile }) => {
  return (
    <SocialShareWrapper className="social-share unstyled" mobile={mobile}>
      <ul>
        <li>
          <Button className="facebook-share-btn" size="sm">
            <Facebook color="#fff" size={18} />
          </Button>
        </li>
        <li>
          <Button className="twitter-share-btn" size="sm">
            <Twitter color="#fff" size={18} />
          </Button>
        </li>
        <li>
          <Button className="linkedin-share-btn" size="sm">
            <Linkedin color="#fff" size={18} />
          </Button>
        </li>
        <li>
          <Button className="envelope-share-btn" size="sm">
            <Mail color="#fff" size={18} />
          </Button>
        </li>
      </ul>
    </SocialShareWrapper>
  );
};

export default SocialShare;

export const SocialShareWrapper = styled.div`
  ul {
    list-style: none;
    padding: 0;

    ${(props) =>
      props.mobile &&
      `
            display:flex;
            flex-wrap: wrap;
            gap: 10px;
            margin-bottom: 0;
            margin-top: 10px;
      `}

    li {
      &:not(:last-child) {
        margin-bottom: 10px;
      }

      button {
        border: unset !important;
        padding: 12px 13px !important;
      }
      .facebook-share-btn {
        background-color: #3b5998 !important;
        svg {
          fill: #fff;
        }
      }

      .twitter-share-btn {
        background-color: #1da1f2 !important;
        svg {
          fill: #fff;
        }
      }

      .linkedin-share-btn {
        background-color: #0077b5 !important;
        svg {
          fill: #fff;
        }
      }

      .envelope-share-btn {
        background-color: var(--primary) !important;
      }
    }
  }
`;

import React from "react";
import { Download, ExternalLink, Play } from "react-feather";
import { Link } from "react-router-dom";
import { Button, Col, Container, Row } from "reactstrap";
import styled from "styled-components";

// images
import resourceIcon from "../../../assets/images/resource-icon.svg";
import placeholderImage from "../../../assets/images/resource-placeholder.jpg";

const VideoWithMinimalContent = () => {
  return (
    <PageTemplate id="page-wrapper" className="pb-6 pt-5 pt-lg-6">
      <Container>
        <div className="mb-5 content-wrapper">
          <div className="video-wrapper mb-5">
            <video
              src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
              className="rounded overflow-hidden"
              controls
              muted
              width={"100%"}
            />
          </div>
          <Row className="title-container">
            <Col md="12" lg="8" xl="9" className="title-warpper">
              <h2 className="mb-0">Video Title</h2>
              <hr />
              <div class="meta d-flex align-items-center flex-wrap">
                <div className="date mr-3">MM/DD/YYYY</div>
                <div className="category">CATEGORY: TAGS</div>
              </div>
            </Col>
            <Col sm="6" md="5" lg="4" xl="3" className="mt-3 mt-lg-0">
              <Button
                color="secondary"
                size=""
                block
                tag="a"
                download
                outline
                className="mb-40"
              >
                DOWNLOAD SLIDEDECK
              </Button>
            </Col>
          </Row>

          <div className="content mt-5">
            <p>
              Lorem ipsum dolor sit amet consectetur. Diam dolor diam erat
              fermentum. Et est purus sit eget lacus. Dictumst morbi hendrerit
              sed venenatis mauris lobortis orci eget nisl. Ac sollicitudin quis
              et amet venenatis. Aliquet mi a sem sit libero vitae adipiscing.
              Sit elit id duis nibh lacinia scelerisque ac nisl.
            </p>
            <p>
              Lorem ipsum dolor sit amet consectetur. Diam dolor diam erat
              fermentum. Et est purus sit eget lacus. Dictumst morbi hendrerit
              sed venenatis mauris lobortis orci eget nisl. Ac sollicitudin quis
              et amet venenatis. Aliquet mi a sem sit libero vitae adipiscing.
              Sit elit id duis nibh lacinia scelerisque ac nisl. Lorem ipsum
              dolor sit amet consectetur. Diam dolor diam erat fermentum. Et est
              purus sit eget lacus. Dictumst morbi hendrerit sed venenatis
              mauris lobortis orci eget nisl. Ac sollicitudin quis et amet
              venenatis. Aliquet mi a sem sit libero vitae adipiscing. Sit elit
              id duis nibh lacinia scelerisque ac nisl.
            </p>
            <p>
              Lorem ipsum dolor sit amet consectetur. Diam dolor diam erat
              fermentum. Et est purus sit eget lacus. Dictumst morbi hendrerit
              sed venenatis mauris lobortis orci eget nisl. Ac sollicitudin quis
              et amet venenatis. Aliquet mi a sem sit libero vitae adipiscing.
              Sit elit id duis nibh lacinia scelerisque ac nisl.
            </p>
          </div>
        </div>

        <SidebarWrapper>
          <div className="recommended-resources rounded">
            <h3 className="mb-3">Recommended Videos</h3>
            <Row>
              <Col xl="3" md="4" sm="6" className="mb-4">
                <Link to="#" className="video-item">
                  <img
                    src="https://source.unsplash.com/random/?Nature/"
                    alt="crypto"
                    width={"100%"}
                  />
                  <div className="icon">
                    <Play size={"30"} />
                  </div>
                </Link>
              </Col>

              <Col xl="3" md="4" sm="6" className="mb-4">
                <Link to="#" className="video-item">
                  <img
                    src="https://source.unsplash.com/random/?Science/"
                    alt="crypto"
                    width={"100%"}
                  />
                  <div className="icon">
                    <Play size={"30"} />
                  </div>
                </Link>
              </Col>

              <Col xl="3" md="4" sm="6" className="mb-4">
                <Link to="#" className="video-item">
                  <img
                    src="https://source.unsplash.com/random/?Conference/"
                    alt="crypto"
                    width={"100%"}
                  />
                  <div className="icon">
                    <Play size={"30"} />
                  </div>
                </Link>
              </Col>

              <Col xl="3" md="4" sm="6" className="mb-4">
                <Link to="#" className="video-item">
                  <img
                    src="https://source.unsplash.com/random/?Cryptocurrency/"
                    alt="crypto"
                    width={"100%"}
                  />
                  <div className="icon">
                    <Play size={"30"} />
                  </div>
                </Link>
              </Col>
            </Row>
          </div>
        </SidebarWrapper>
      </Container>
    </PageTemplate>
  );
};

export default VideoWithMinimalContent;

export const PageTemplate = styled.section`
  background: linear-gradient(180deg, #d5e5ef 0%, rgba(213, 229, 239, 0) 40%);

  hr {
    color: #d1d3d4;
  }

  .content {
    & > :last-child {
      margin-bottom: 0;
    }
  }

  .date {
    color: #898a8d;
    font-family: var(--primaryFont);
    font-size: 12px;
    line-height: 18px;
    font-weight: 800;
    letter-spacing: 0.75px;
  }

  .category {
    color: #4180be;
    font-family: var(--primaryFont);
    font-size: 12px;
    line-height: 18px;
    font-weight: 800;
    letter-spacing: 0.75px;
  }
`;

export const SidebarWrapper = styled.aside`
  .mb-40 {
    margin-bottom: 40px;
  }

  .recommended-resources {
    background-color: #f3f3f3;
    padding: 25px 40px 16px 40px;

    svg {
      stroke-width: 2.6px;
    }

    .video-item {
      position: relative;
      display: block;

      img {
        aspect-ratio: 16/9;
        object-fit: cover;
      }
      .icon {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.5);

        svg {
          fill: #fff;
          stroke: #fff;
        }
      }
    }
  }

  h6 {
    margin-bottom: 30px;
  }
`;

import React from "react";
import { SinglePostWrapper } from "./_singlePostStyle";
import { Col, Row, Container } from "reactstrap";
import SocialShare from "../../../components/social-share/SocialShare";
import { FileText } from "react-feather";
import PostTeaser from "../../../components/post-teaser/PostTeaser";
import { useAuth0 } from '@auth0/auth0-react';

const SinglePost = () => {
  const {
    isAuthenticated
  } = useAuth0();
  return (
    <>
      <SinglePostWrapper>
        <Container>
          <Row>
            <Col className="col-auto d-none d-lg-block">
              <SocialShare />
            </Col>
            <Col lg="8" md="8">
              <article>
                <div className="post-wrapper">
                  <div className="post-header">
                    <h2 className="post-title">
                    How Effective Is the Government’s Campaign Against Hospital Mergers?
                    </h2>
                    <span className="meta-date">October, 2022</span>
                    <div className="d-block d-lg-none">
                      <SocialShare mobile="mobile" />
                    </div>
                  </div>
                  <div className="post-content">
                    <p>
                      Nibh etiam auctor aenean nisi cubilia amet pretium augue
                      egestas molestie netus eget enim massa mollis aliquam
                      fames morbi erat torquent metus viverra ligula ex semper
                      sagittis lobortis cursus vivamus tempus pharetra
                      condimentum faucibus malesuada phasellus sollicitudin
                      magnis quis a lectus nec maximus mattis dapibus
                    </p>
                    <p>
                      Fringilla consectetuer neque dis dignissim ullamcorper
                      class et facilisi justo penatibus curabitur scelerisque
                      sem ridiculus phasellus platea risus maecenas posuere
                      netus nisl tellus cursus iaculis per metus vel imperdiet
                      senectus lacinia nunc ut mus ac bibendum dui ultricies
                      dictum tincidunt pellentesque rhoncus nec interdum
                      suspendisse
                    </p>
                    {isAuthenticated ? (
                      <div>
        <p>
        Penatibus sollicitudin venenatis maximus dictum vitae
        massa montes hac finibus pretium habitasse si leo eros
        porta mus ultricies morbi feugiat nulla eu fames auctor
        ipsum curabitur hendrerit fringilla enim aptent suscipit
        sem eget quis molestie tellus lacinia natoque conubia
        pellentesque amet consectetur primis volutpat rhoncus dui
        in interdum class urna consequat ut posuere dignissim
        parturient nec euismod id vehicula sapien et dictumst
        potenti ligula nibh mauris scelerisque ex placerat odio
        ornare pede praesent condimentum per
      </p>

      <p>
        Sollicitudin venenatis maximus dictum vitae
        massa montes hac finibus pretium habitasse si leo eros
        porta mus ultricies morbi feugiat nulla eu fames auctor
        ipsum curabitur hendrerit fringilla enim aptent suscipit
        sem eget quis molestie tellus lacinia natoque conubia
        pellentesque amet consectetur primis volutpat rhoncus dui
        in interdum class urna consequat ut posuere dignissim
        parturient nec euismod id vehicula sapien et dictumst
        potenti ligula nibh mauris scelerisque ex placerat odio
        ornare pede praesent condimentum per. interdum class urna consequat ut posuere dignissim
        parturient nec euismod id vehicula sapien et dictumst
        potenti ligula nibh mauris scelerisque ex placerat odio
        ornare pede praesent condimentum per. interdum class urna consequat ut posuere dignissim
        parturient nec euismod id vehicula sapien et dictumst
        potenti ligula nibh mauris scelerisque ex placerat odio
        ornare pede praesent condimentum per
      </p>
      <p>
        Mus ultricies morbi feugiat nulla eu fames auctor
        ipsum curabitur hendrerit fringilla enim aptent suscipit
        sem eget quis molestie tellus lacinia natoque conubia
        pellentesque amet consectetur primis volutpat rhoncus dui
        in interdum class urna consequat ut posuere dignissim
        parturient nec euismod id vehicula sapien et dictumst
        potenti ligula nibh mauris scelerisque ex placerat odio
        ornare pede praesent condimentum per. Tellus lacinia natoque conubia
        pellentesque amet consectetur primis volutpat rhoncus dui
        in interdum class urna consequat ut posuere dignissim
        parturient nec euismod id vehicula sapien et dictumst
        potenti ligula nibh mauris scelerisque ex placerat odio
        ornare pede praesent condimentum per. Sem eget quis molestie tellus 
        lacinia natoque conubia pellentesque amet consectetur primis volutpat 
        rhoncus dui in interdum class urna consequat ut posuere dignissim
        parturient nec euismod id vehicula sapien et dictumst
        potenti ligula nibh mauris scelerisque ex placerat odio
        ornare pede praesent condimentum per. Tellus lacinia natoque conubia
        pellentesque amet consectetur primis volutpat rhoncus dui
        in interdum class urna consequat ut posuere dignissim
        parturient nec euismod id vehicula sapien et dictumst
        potenti ligula nibh mauris scelerisque ex placerat odio
        ornare pede praesent condimentum per
      </p>
      </div>
      ) : (
        <p>...</p>
      )}
                    
                  </div>
                </div>
              </article>
            </Col>

            <Col lg="3" md="4">
              <div className="post-sidebar">
                <a href="#" target="blank" className="download-button">
                  <i className="fa-solid fa-file-pdf"></i>
                  <span className="button-text d-flex align-items-center justify-content-center">
                    <FileText size={16} className="mr-25" /> DOWNLOAD (PDF)
                  </span>
                </a>

                <div className="post-img">
                  <img
                    src="https://communityoncology.org/wp-content/uploads/2020/04/IMG_2175.jpg"
                    alt=""
                    width={"100%"}
                  />
                </div>
                <div className="related-posts-wraper">
                  <h4>More Related Content</h4>
                  <div className="related-posts">
                    <h2>Related Posts</h2>
                    <ul className="related-posts-list">
                      <li className="related-posts-title">
                        <a className="related-posts-title-link" href="#">
                          Aptent leo tristique velit vulputate eleifend
                        </a>
                      </li>
                      <li className="related-posts-title">
                        <a className="related-posts-title-link" href="#">
                          Aptent leo tristique velit vulputate eleifend
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </SinglePostWrapper>
      <PostTeaser />
    </>
  );
};

export default SinglePost;

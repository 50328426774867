import React, { useState } from "react";
import styled from "styled-components";
import { Card } from "reactstrap";
import { ChevronLeft, ChevronRight, Lock } from "react-feather";
import Slider from "react-slick";

// Initiatives
import copa from "../../assets/images/initiatives/COPA.png";
import cpan from "../../assets/images/initiatives/CPAN.png";
import eom from "../../assets/images/initiatives/eom.svg";
import laws from "../../assets/images/initiatives/coa_laws_logo.svg";
import coacan from "../../assets/images/initiatives/COACAN.png";
import coafi from "../../assets/images/initiatives/coafellows.svg";
import omh from "../../assets/images/initiatives/omh.png";
import coa from "../../assets/images/initiatives/coa-logo.png";

const Initiatives = () => {
  const [initiativeSelect, setInitiativeSelect] = useState(null);

  const selectToggle = (id) => {
    if (initiativeSelect !== id) {
      setInitiativeSelect(id);
    } else {
      setInitiativeSelect(null);
    }
  };

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: (
      <span className="prev-arrow">
        <ChevronLeft color="priary" />
      </span>
    ),
    nextArrow: (
      <span className="next-arrow">
        <ChevronRight />
      </span>
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="dashboard-wrapper">
      <InitiativeWrapper className="px-1">
        <div className="scroll-wrap">
          <Slider {...sliderSettings}>
            <a href="https://communityoncology.org/" target={"_self"} className="slide-item ">
              <Card className={`item `}>
                <img src={coa} alt="copa" />
              </Card>
            </a>

            <a href="https://coapharmacy.com/" target={"_self"} className="slide-item ">
              <Card className={`item `}>
                <img src={copa} alt="copa" />
              </Card>
            </a>

            <a href="https://coacan.org" target={"_self"} className="slide-item">
              <Card className={`item  `}>
                <img src={coacan} alt="coacan" />
              </Card>
            </a>

            <a href="https://coaadvocacy.org/" target={"_self"} className="slide-item">
              <Card className={` item `}>
                <img src={cpan} alt="cpan" />
              </Card>
            </a>

            <a href="https://communityoncology.org/eom" target={"_self"} className="slide-item">
              <Card className={`item `}>
                <img src={eom} alt="eom" />
              </Card>
            </a>

            <a href="https://medicalhomeoncology.org/" target={"_self"} className="slide-item">
              <Card className={`item  `}>
                <img src={omh} alt="omh" />
              </Card>
            </a>

            <a href="https://coafellows.org/" target={"_self"} className="slide-item">
              <Card className={`item `}>
                <img src={coafi} alt="coafi" />
              </Card>
            </a>
          </Slider>
        </div>
      </InitiativeWrapper>
    </section>
  );
};

export default Initiatives;

export const InitiativeWrapper = styled.section`
  background-color: #efefef;

  .slick-arrow {
    height: 100%;
    display: flex;
    align-items: center;
    width: 50px;

    &::before,
    &::after {
      display: none;
    }

    &.slick-prev {
      left: -3px !important;
      background: linear-gradient(90deg, rgba(0, 0, 0, 0.2), transparent);
      z-index: 2;
    }

    &.slick-next {
      right: -3px;
      background: linear-gradient(268deg, rgba(0, 0, 0, 0.2), transparent);
      svg {
        position: absolute !important;
        right: 0px;
      }
    }

    &.slick-disabled {
      opacity: 0;
    }

    svg {
      stroke: var(--primary);
      height: 100%;
    }
  }
  .item {
    padding: 15px;
    margin: 15px;
    position: relative;
    min-height: 118px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 300ms ease;
    overflow: hidden;

    img {
      max-height: 80px;
      width: 100%;
      object-fit: contain;
    }

    .lock-wrapper {
      position: absolute;
      top: 0%;
      left: 0;
      width: 100%;
      height: 100%;
      content: "";
      background-color: rgba(23, 51, 114, 0.6);
      display: flex;
      align-items: center;
      justify-content: center;
      display: none;

      img {
        width: 21px;
      }
    }

    &:hover {
      transform: scale(1.02);
    }

    &.locked {
      pointer-events: none;
      .lock-wrapper {
        display: flex;
      }
    }

    &.selected {
      background-color: #d5e5ef;
    }
  }
`;

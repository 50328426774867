// ** Initial State
const initialState = {
  password: null,
  userProfile: null,
  userRegistration: null,
  userAvatar: null
}

const users = (state = initialState, action) => {
  switch (action.type) {
    
    case "UPDATE_USER_PASSWORD":
      return { ...state, password: action.data };
       
    case 'CURRENT_USER':
      return { ...state, userProfile: action.data };

    case 'SET_CURRENT_USER':
      return { ...state, userProfile: action.data };
       
    case 'UPDATE_REG_USER':
        return { ...state, userRegistration: action.data };
        
    case 'UPDATE_USER_PROFILE_AVATAR':
        return { ...state, userAvatar: action.data };
         
    default:
      return { ...state }
  }
}
export default users

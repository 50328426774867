import React from "react";
import { Award } from "react-feather";
import { Link } from "react-router-dom";
import { Button, Col, Container, Row } from "reactstrap";
import styled from "styled-components";

import ACHCPartner from "./../../assets/images/achc-partner.jpg";
import COPAIcon from "./../../assets/images/copa-icon.png";

const index = () => {
  return (
    <div className="page-wrapper mt-6">
      <Container>
        <h1 className="text-center">ACHC Pharmacy Accreditation</h1>
      </Container>
      <section className="mt-5 mb-6">
        <Container>
          <Row>
            <Col
              lg="6"
              className="mb-5 mb-lg-0 border-right pr-lg-5"
              style={{ borderWidth: "2px" }}
            >
              <div className="text-center mb-4">
                <img src={ACHCPartner} alt="achc partner" height={"100px"} />
              </div>
              <h3 className="text-center">Specialty Pharmacy Accreditation</h3>
              <p>
                <a href="http://www.achc.org/" target="_self" rel="noopener">
                  ACHC
                </a>
                <span>
                  {" "}
                  and COPA have partnered to provide members with a customized
                  suite of specialty pharmacy accreditation offerings, including
                  discounts on educational resources and accreditation programs.
                  ACHC was the first accrediting body to offer specialty
                  pharmacy accreditation. By undergoing ACHC accreditation,
                  pharmacies demonstrate their commitment to providing the
                  highest quality service by complying with stringent national
                  regulations and industry best practices.
                </span>
              </p>
              <p>
                To access the Specialty Pharmacy Accreditation Standards, click{" "}
                <Link to="/resources/achc-pharmacy-accreditation/specialty-pharmacy-accreditation">here </Link>
                and join as a member to enjoy access to many free resources and
                tools to help your pharmacy achieve ACHC Specialty Pharmacy
                Accreditation.
              </p>
              <div className="text-center mt-4">
                <Link to="/resources/achc-pharmacy-accreditation/specialty-pharmacy-accreditation">
                  <Button color="secondary">ACCESS STANDARDS</Button>
                </Link>
              </div>
            </Col>

            <Col lg="6" className="pl-lg-5">
              <div className="text-center mb-4 ">
                <img src={COPAIcon} alt="achc partner" height={"100px"} />
              </div>
              <h3 className="text-center">Oncology Distinction</h3>
              <p>
                <a href="http://www.achc.org/" target="_self" rel="noopener">
                  ACHC
                </a>{" "}
                and COPA have successfully completed an oncology accreditation
                set of standards specific to pharmacies that dispense oral
                oncolytics. Pharmacies will still have to first achieve ACHC
                pharmacy accreditation prior to attaining these oncology
                standards.
              </p>
              <p>
                <a href="http://www.achc.org/" target={"_self"}>
                  ACHC
                </a>{" "}
                and COPA have partnered to provide members with a customized
                suite of specialty pharmacy accreditation offerings, including
                discounts on educational resources and accreditation programs.
                ACHC was the first accrediting body to offer specialty pharmacy
                accreditation.
              </p>

              <p>
                To access the Oncology Distinction Standards, click{" "}
                <Link to="/resources/achc-pharmacy-accreditation/oncology-distinction">here </Link> and join as
                a member and enjoy access to many free resources and tools to
                help your pharmacy achieve ACHC Oncology Accreditation.
              </p>
              <div className="text-center mt-4">
                <Link to="/resources/achc-pharmacy-accreditation/oncology-distinction">
                  <Button color="secondary">ACCESS STANDARDS</Button>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <Quote className="pt-6 pb-5">
        <Container>
          <blockquote className="quote px-6">
            Going through SRX-ONC Accreditation with ACHC has allowed my
            practice to operationalize new and improved requirements, which has
            helped us to provide the highest level of patient care available in
            the oncology industry. This has led to improvements across our
            practice, and has increased the value of the care we provide to our
            patients.
          </blockquote>
          <small className="mt-2 text-right mb-0 d-block text-primary pr-6">
            – JOSHUA COX, DAYTON PHYSICIANS NETWORK
          </small>
        </Container>
      </Quote>

      <Parallax className="py-6">
        <Container className="text-center">
          <div
            className="mb-3 border p-2 d-inline-block rounded border-3px"
            style={{ borderWidth: "3px" }}
          >
            <Award size="60" color="#fff" />
          </div>
          <h3>Interested in ACHC Accreditation?</h3>
          <p className="mb-3">
            Join COPA today to gain access to special COPA Member ACHC
            Accreditation pricing, oncology pharmacy standards, and access to
            tools that facilitate becoming accredited.
          </p>
          <a href="https://coapharmacy2.com/copa-membership/" target={"_self"}>
            <Button color="secondary">JOIN TODAY</Button>
          </a>
        </Container>
      </Parallax>
    </div>
  );
};

export default index;

export const Quote = styled.div`
  background-color: #f4f4f4;
  font-size: 22px;
  color: var(--secondary);
  .quote {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      left: 0px;
      top: 0px;
      width: 40px;
      height: 40px;
      background-image: url("https://coapharmacy2.com/wp-content/uploads/2022/02/Quote-one-200x200.png");
      background-repeat: no-repeat;
      background-size: contain;
    }

    &::after {
      content: "";
      position: absolute;
      right: 0px;
      bottom: 0px;
      width: 40px;
      height: 40px;
      background-image: url("https://coapharmacy2.com/wp-content/uploads/2022/02/Quote-two-200x200.png");
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
`;

export const Parallax = styled.section`
  background-image: url("https://coapharmacy2.com/wp-content/uploads/2023/01/parallax.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;

  .border-3px {
    border-width: 3px !important;
  }

  .container {
    z-index: 2;
    position: relative;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(19, 61, 141, 0.6);
    z-index: 1;
  }
  p {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    color: #fff;
  }
  h3 {
    color: #fff;
  }
`;

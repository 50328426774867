import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Calendar } from "react-feather";
import { Card, CardBody } from "reactstrap";

const VerticleArticle = ({ target, image, title, date }) => {
  return (
    <VerticleArticleWrapper>
      <Link to={target}>
        <Card className="border-0 article-vertical">
          <img
            src={image}
            alt="artilce image"
            className="rounded featured-image-thumb"
          />
          <CardBody className="px-0 ">
            <p className="meta-date mb-1 d-flex align-items-center">
              <Calendar size="13" className="mr-1" /> {date}
            </p>
            <h5 className="mb-0 weight-700">{title}</h5>
          </CardBody>
        </Card>
      </Link>
    </VerticleArticleWrapper>
  );
};

export default VerticleArticle;

export const VerticleArticleWrapper = styled.div`
  .featured-image-thumb {
    transition: all 0.3s ease;
    min-height: 200px;
    max-height: 200px;
    object-fit: cover;
  }

  &:hover {
    .featured-image-thumb {
      transform: scale(1.02);
    }
  }
`;

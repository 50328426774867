export const menuItems = [
  {
    title: "Education & Publications",
    link: "#",
    target: "_self",
    submenu: [
      {
        title: "Oncology Pharmacy Fundamentals Course",
        link: "https://mycoa.communityoncology.org/education-publications/pharmacist-education/oncology-pharmacy-fundamentals-course",
        target: "_self",
      },
      {
        title: "PBM Horror Stories",
        link: "/education-publications/pbm-horror-stories",
        target: "_self",
      },
      {
        title: "All COA Education & Publications",
        link: "https://mycoa.communityoncology.org/education-publications",
        target: "_self",
      },
    ],
  },
  {
    title: "Resources",
    submenu: [
      {
        title: "Pharmacy Accreditation (ACHC)",
        link: "/resources/achc-pharmacy-accreditation/",
        target: "_self",
      },
      {
        title: "Patient Feedback Tools",
        link: "https://coapharmacy.com/patient-feedback-tools/",
        target: "_self",
      },
      {
        title: "Practice Benchmarking Software (COAnalyzer)",
        link: "https://coanalyzer.net/",
        target: "_self",
      },
      {
        title: "Addressing PBM & Insurer Abuses",
        link: "/resources/pbm-tools/resources-for-pbm-and-insurer-abuses",
        target: "_self",
      },
      {
        title: "Patient Advocacy & Education Resources",
        link: "https://coapharmacy.com/resources/patient-advocacy-education-resources/",
        target: "_self",
      },
      {
        title: "Partner Resources",
        link: "https://coapharmacy.com/resources/partner-resources",
        target: "_self",
      },
      {
        title: "Policy Tracker & Maps",
        link: "https://laws.mycoa.io",
        target: "_self",
      },
    ],
  },
  {
    title: "Meetings",
    link: "/events/member-meetings/",
    target: "_self",
    submenu: [
      {
        title: "Monthly Membership Calls & Recordings",
        link: "/events/member-meetings/",
        target: "_self",
      },
    ],
  },

  {
    title: "Members",
    link: "#",
    target: "_self",
    submenu: [
      {
        title: "Join COPA",
        link: "https://mycoa.io/",
        target: "_self",
      },
      {
        title: "Subscribe To Updates",
        link: "https://coapharmacy.com/subscribe-to-updates/",
        target: "_self",
      },
    ],
  },
  {
    title: "About",
    link: "#",
    target: "_self",
    submenu: [
      {
        title: "About COPA",
        link: "https://coapharmacy.com/about/",
        target: "_self",
      },
      {
        title: "What is Community Oncology?",
        link: "/about/what-is-community-oncology/",
        target: "_self",
      },
      {
        title: "Leadership & Advisory Board",
        link: "https://coapharmacy.com/about/#leadership",
        target: "_self",
      },

      {
        title: "Our Members & Community",
        link: "https://coapharmacy.com/about/pharmacy-community/",
        target: "_self",
      },

      {
        title: "Corporate Partners & Supporters",
        link: "https://coapharmacy.com/about/corporate-partners-supporters/",
        target: "_self",
      },
      {
        title: "Contact Us",
        link: "https://coapharmacy.com/contact/",
        target: "_self",
      },
    ],
  },
];

import styled from "styled-components";

export const HomeWrapper = styled.div`
  padding: 30px;
`;

export const Title = styled.div`
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translateY(-50%);
    height: 1px;
    background-color: #d1d3d4;
  }
  span {
    background-color: #fff;
    display: inline-block;
    position: relative;
    z-index: 1;
    padding-right: 15px;
    font-size: 16px;
  }
`;

export const InitiativeContainer = styled.section`
  background-color: #d5e5ef;

  .container-wrapper {
    padding-top: 28px;
    padding-right: 28px;
    padding-left: 28px;
  }

  .wave-top,
  .wave-bottom {
    /* transform: translateY(50px); */
    width: 100%;
  }

  .initiative-card-wrapper {
    .card {
      position: relative;
      overflow: hidden;
      margin-bottom: 28px;

      img {
        overflow: hidden;
        position: relative;
        border-radius: 0.428rem;
        transform: scale(1.05);
        width: 100%;
        transition: all 300ms ease;
      }
      p {
        font-size: 18px;
        font-weight: 500;
        text-align: center;
        background-color: var(--primary);
        color: #fff;
        position: relative;

        padding: 15px 10px;
      }

      &:hover {
        img {
          transform: scale(1.1);
        }
      }
    }

    /* @media (min-width: 1033px) and (max-width: 1347px) {
      p {
        min-height: 72px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    @media (min-width: 992px) and (max-width: 1032px) {
      p {
        min-height: 93px;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    @media (min-width: 768px) and (max-width: 911px) {
      p {
        min-height: 72px;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    } */
  }
`;

import axios from 'axios'
import authHeader from '../../../auth/Login'

// ** Current User
export const currentUser = (id) => {
  return async dispatch => {
    await axios.get(`/user/${id}`, { headers: authHeader() })
      .then(response => {
        dispatch({
          type: 'CURRENT_USER',
          data: response.data
        })
      })
      .catch(err => console.log(err))
  }
}

// ** Set Current User
export const setCurrentUser = (id) => {
  return async dispatch => {
        dispatch({
          type: 'SET_CURRENT_USER',
          data: null
        })
  }
}

//Update User Profile
export const updateUserProfile = (payload) => {
  return async dispatch => {
    await axios.post('/user/register-profile-update', payload).then(response => {
    dispatch({
        type: 'UPDATE_REG_USER',
        data: response.data
    })
  })
}
}

export const updateUserPassword = (data) => {
  return async dispatch => {
    await axios.post(`/user/update-password`,data).then(response => {
      dispatch({
        type: 'UPDATE_USER_PASSWORD',
        data: response.data
      })
    })
  }
}

export const updateUserProfileAvatar = (data) => {
  return async dispatch => {
    await axios.post(`/user/update-avatar`,data).then(response => {
      dispatch({
        type: 'UPDATE_USER_PROFILE_AVATAR',
        data: response.data
      })
    })
  }
}

import React from "react";
import { Download, ExternalLink } from "react-feather";
import { Link } from "react-router-dom";
import { Button, Col, Container, Row } from "reactstrap";
import styled from "styled-components";

// images
import resourceIcon from "../../../assets/images/resource-icon.svg";
import placeholderImage from "../../../assets/images/resource-placeholder.jpg";

const ResourcesAtBottom = () => {
  return (
    <PageTemplate id="page-wrapper" className="pb-6 pt-5 pt-lg-6">
      <Container>
        <div className="mb-5 content-wrapper">
          <Row className="title-container">
            <Col md="12" lg="9" className="title-warpper">
              <h2 className="mb-0">Resource Title</h2>
              <hr />
              <div class="meta d-flex align-items-center flex-wrap">
                <div className="date mr-3">MM/DD/YYYY</div>
                <div className="category">CATEGORY:TAGS</div>
              </div>
            </Col>
            <Col sm="5" md="4" lg="3" className="mt-3 mt-lg-0">
              <Button
                color="secondary"
                size=""
                block
                tag="a"
                download
                outline
                className="mb-40"
              >
                DOWNLOAD
              </Button>
            </Col>
          </Row>

          <div className="content mt-5">
            <p>
              Lorem ipsum dolor sit amet consectetur. Diam dolor diam erat
              fermentum. Et est purus sit eget lacus. Dictumst morbi hendrerit
              sed venenatis mauris lobortis orci eget nisl. Ac sollicitudin quis
              et amet venenatis. Aliquet mi a sem sit libero vitae adipiscing.
              Sit elit id duis nibh lacinia scelerisque ac nisl.
            </p>
            <p>
              Lorem ipsum dolor sit amet consectetur. Diam dolor diam erat
              fermentum. Et est purus sit eget lacus. Dictumst morbi hendrerit
              sed venenatis mauris lobortis orci eget nisl. Ac sollicitudin quis
              et amet venenatis. Aliquet mi a sem sit libero vitae adipiscing.
              Sit elit id duis nibh lacinia scelerisque ac nisl. Lorem ipsum
              dolor sit amet consectetur. Diam dolor diam erat fermentum. Et est
              purus sit eget lacus. Dictumst morbi hendrerit sed venenatis
              mauris lobortis orci eget nisl. Ac sollicitudin quis et amet
              venenatis. Aliquet mi a sem sit libero vitae adipiscing. Sit elit
              id duis nibh lacinia scelerisque ac nisl.
            </p>
            <p>
              Lorem ipsum dolor sit amet consectetur. Diam dolor diam erat
              fermentum. Et est purus sit eget lacus. Dictumst morbi hendrerit
              sed venenatis mauris lobortis orci eget nisl. Ac sollicitudin quis
              et amet venenatis. Aliquet mi a sem sit libero vitae adipiscing.
              Sit elit id duis nibh lacinia scelerisque ac nisl.
            </p>
          </div>
        </div>

        <SidebarWrapper>
          <div className="recommended-resources rounded">
            <h3 className="mb-3">Recommended Resources</h3>
            <div className="resource-item d-flex align-items-center flex-wrap">
              <img src={resourceIcon} alt="icon" className="icon mr-4" />
              <div className="resource-details mr-4">
                <h5>
                  <Link to="#" className="text-secondary">
                    Sample Resource Title
                  </Link>
                </h5>
                <p className="excerpt">
                  Lorem ipsum dolor sit amet consectetur. Adipiscing dis lacus
                  non tristique. Nisi dolor tellus viverra cras interdum sociis
                  egestas dolor netus.
                </p>
                <div
                  class="meta d-flex align-items-center flex-wrap"
                  style={{ gap: "20px" }}
                >
                  <Link to="#" className="category">
                    CATEGORY
                  </Link>{" "}
                  <Link to="#" className="sub-category">
                    SUB-CATEGORY
                  </Link>{" "}
                  <div className="date">DATE</div>
                  <Link to="#" class="meta d-flex align-items-center">
                    <ExternalLink size={"14"} className="link-icons" />
                  </Link>
                  <Link to="#" class="meta d-flex align-items-center">
                    <Download size={"14"} className="link-icons" />
                  </Link>
                </div>
              </div>
              <div className="featured-image">
                <img
                  src={placeholderImage}
                  alt="featured image"
                  className="w-100 rounded"
                />
              </div>
            </div>
            {/* Resource item end */}

            <div className="resource-item d-flex align-items-center flex-wrap">
              <img src={resourceIcon} alt="icon" className="icon mr-4" />
              <div className="resource-details mr-4">
                <h5>
                  <Link to="#" className="text-secondary">
                    Sample Resource Title
                  </Link>
                </h5>
                <p className="excerpt">
                  Lorem ipsum dolor sit amet consectetur. Adipiscing dis lacus
                  non tristique. Nisi dolor tellus viverra cras interdum sociis
                  egestas dolor netus.
                </p>
                <div
                  class="meta d-flex align-items-center flex-wrap"
                  style={{ gap: "20px" }}
                >
                  <Link to="#" className="category">
                    CATEGORY
                  </Link>{" "}
                  <Link to="#" className="sub-category">
                    SUB-CATEGORY
                  </Link>{" "}
                  <div className="date">DATE</div>
                  <Link to="#" class="meta d-flex align-items-center">
                    <ExternalLink size={"14"} className="link-icons" />
                  </Link>
                  <Link to="#" class="meta d-flex align-items-center">
                    <Download size={"14"} className="link-icons" />
                  </Link>
                </div>
              </div>
              <div className="featured-image">
                <img
                  src={placeholderImage}
                  alt="featured image"
                  className="w-100 rounded"
                />
              </div>
            </div>
            {/* Resource item end */}

            <div className="resource-item d-flex align-items-center flex-wrap">
              <img src={resourceIcon} alt="icon" className="icon mr-4" />
              <div className="resource-details mr-4">
                <h5>
                  <Link to="#" className="text-secondary">
                    Sample Resource Title
                  </Link>
                </h5>
                <p className="excerpt">
                  Lorem ipsum dolor sit amet consectetur. Adipiscing dis lacus
                  non tristique. Nisi dolor tellus viverra cras interdum sociis
                  egestas dolor netus.
                </p>
                <div
                  class="meta d-flex align-items-center flex-wrap"
                  style={{ gap: "20px" }}
                >
                  <Link to="#" className="category">
                    CATEGORY
                  </Link>{" "}
                  <Link to="#" className="sub-category">
                    SUB-CATEGORY
                  </Link>{" "}
                  <div className="date">DATE</div>
                  <Link to="#" class="meta d-flex align-items-center">
                    <ExternalLink size={"14"} className="link-icons" />
                  </Link>
                  <Link to="#" class="meta d-flex align-items-center">
                    <Download size={"14"} className="link-icons" />
                  </Link>
                </div>
              </div>
              <div className="featured-image">
                <img
                  src={placeholderImage}
                  alt="featured image"
                  className="w-100 rounded"
                />
              </div>
            </div>
            {/* Resource item end */}
          </div>
        </SidebarWrapper>
      </Container>
    </PageTemplate>
  );
};

export default ResourcesAtBottom;

export const PageTemplate = styled.section`
  background: linear-gradient(180deg, #d5e5ef 0%, rgba(213, 229, 239, 0) 40%);

  hr {
    color: #d1d3d4;
  }

  .content {
    & > :last-child {
      margin-bottom: 0;
    }
  }

  .date {
    color: #898a8d;
    font-family: var(--primaryFont);
    font-size: 12px;
    line-height: 18px;
    font-weight: 800;
    letter-spacing: 0.75px;
  }

  .category {
    color: #4180be;
    font-family: var(--primaryFont);
    font-size: 12px;
    line-height: 18px;
    font-weight: 800;
    letter-spacing: 0.75px;
  }
`;

export const SidebarWrapper = styled.aside`
  .mb-40 {
    margin-bottom: 40px;
  }

  .recommended-resources {
    background-color: #f3f3f3;
    padding: 25px 40px 40px 40px;

    svg {
      stroke-width: 2.6px;
    }

    .resource-item {
      &:not(:last-child) {
        margin-bottom: 40px;
        padding-bottom: 40px;
        border-bottom: 1px solid #d1d3d4;
      }
      .icon {
        flex: 0 0 100px;
      }

      .resource-details {
        flex: 2;

        .category,
        .sub-category,
        .date {
          color: #4180be;
          font-family: var(--primaryFont);
          font-size: 12px;
          line-height: 18px;
          font-weight: 800;
          letter-spacing: 0.75px;
        }

        .excerpt {
          color: #101c3b;
        }
        .link-icons {
          color: var(--secondary);
        }

        .meta {
          .category,
          .sub-category {
            position: relative;

            &::after {
              content: "";
              position: absolute;
              right: -10px;
              top: 50%;
              transform: translateY(-50%);
              width: 2px;
              height: 12px;
              background-color: var(--link);
            }
          }
        }
      }

      .featured-image {
        flex: 0 0 200px;
      }

      @media (max-width: 860px) {
        flex-direction: column-reverse;
        .icon {
          display: none;
        }

        .resource-details {
          margin-right: 0 !important;
        }
        .featured-image {
          flex: 1 1 100% !important;
          width: 100%;
          margin-bottom: 20px;
          /* margin-top: 15px; */
        }
      }
    }
  }

  h6 {
    margin-bottom: 30px;
  }
`;

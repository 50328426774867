import React from "react";
import { Col, Container, Row, Card } from "reactstrap";
import VerticleArticle from "../../components/vertical-article/VerticleArticle";
import { HomeWrapper, InitiativeContainer, Title } from "./homeStyles";

import ACHC from "../../assets/images/ACHC.jpg";
import CALLS from "../../assets/images/CALLS.jpg";
import COANALYZER from "../../assets/images/COANALYZER.jpg";
import COMMUNITY from "../../assets/images/COMMUNITY.jpg";
import EDUCATION from "../../assets/images/EDUCATION.jpg";
import LISTSERV from "../../assets/images/LISTSERV.jpg";
import REPORTS from "../../assets/images/REPORTS.jpg";
import MAPS from "../../assets/images/MAPS.jpg";
import PBM from "../../assets/images/PBM.jpg";
import Initiatives from "./Initiatives";

const Home = () => (
  <HomeWrapper>
    <div className="dashbord-wrapper mb-1">
      <Initiatives />
      <Title className="dashboard-title mt-5">
        <span>MYCOA DASHBOARD</span>
      </Title>

      <p className="text-center my-4 text-primary">
        Welcome to myCOA!! As a member, you will have access to exclusive resources, tools,
        listservs, recordings and downloadable materials.
      </p>

      <InitiativeContainer className="">
        <div className="container-wrapper">
          <Row className=" initiative-card-wrapper justify-content-center">
            <Col md="6" lg="4">
              <a href="https://laws.mycoa.io">
                <Card className="signup-card">
                  <img src={MAPS} alt="placeholder" />
                  <p className="mb-0">Policy & Legislation Tracker</p>
                </Card>
              </a>
            </Col>
            <Col md="6" lg="4">
              <a href="https://mycoa.communityoncology.org/events/member-meetings/">
                <Card className="signup-card">
                  <img src={CALLS} alt="placeholder" />
                  <p className="mb-0">Monthly Call Recordings</p>
                </Card>
              </a>
            </Col>
            <Col md="6" lg="4">
              <a href="https://coanalyzer.net">
                <Card className="signup-card">
                  <img src={COANALYZER} alt="placeholder" />
                  <p className="mb-0">COAnalyzer</p>
                </Card>
              </a>
            </Col>

            <Col md="6" lg="4">
              <a href="https://mycoa.coapharmacy.com/achc-pharmacy-accreditation">
                <Card className="signup-card">
                  <img src={ACHC} alt="placeholder" />
                  <p className="mb-0">ACHC Accreditation</p>
                </Card>
              </a>
            </Col>

            <Col md="6" lg="4">
              <a href="https://mycoa.coacan.org/education-publications/">
                <Card className="signup-card">
                  <img src={REPORTS} alt="placeholder" />
                  <p className="mb-0">Education Publications</p>
                </Card>
              </a>
            </Col>

            <Col md="6" lg="4">
              <a href="https://mycoa.coacan.org/resources/pbm-tools/resources-for-pbm-and-insurer-abuses">
                <Card className="signup-card">
                  <img src={PBM} alt="placeholder" />
                  <p className="mb-0">PBM Resources</p>
                </Card>
              </a>
            </Col>

            {/* <Col md="6" lg="4">
              <Card className="signup-card">
                <img src={COMMUNITY} alt="placeholder" />
                <p className="mb-0">Community Collaboration</p>
              </Card>
            </Col> */}

            {/* <Col md="6" lg="4">
              <Card className="signup-card">
                <img src={EDUCATION} alt="placeholder" />
                <p className="mb-0">Educational Resources</p>
              </Card>
            </Col> */}
            {/* <Col md="6" lg="4">
              <Card className="signup-card">
                <img src={LISTSERV} alt="placeholder" />
                <p className="mb-0">Listserv</p>
              </Card>
            </Col> */}
          </Row>
        </div>
      </InitiativeContainer>
    </div>
  </HomeWrapper>
);

export default Home;

import styled from "styled-components";

export const SinglePostWrapper = styled.section`
  background: #fff;
  padding: 64px 0px;

  .hide {
    display: none;
  }

  .layout-wrapper {
    .sidebar {
      max-width: 350px;
      width: 100%;
    }

    .content {
      min-height: calc(100vh - 312px);
    }

    &.fullWidth {
      .sidebar {
        display: none !important;
      }
    }

    @media (min-width: 992px) {
      &.fullWidth {
        .content {
          max-width: calc(100% - 74px);
          width: 100%;
        }
      }

      /* Right sidebar */

      &.rightSidebar {
        .content {
          max-width: calc(100% - 424px);
          width: 100%;
        }
      }

      /* Left sidebar */

      &.leftSidebar {
        flex-direction: row-reverse;

        .content {
          max-width: calc(100% - 424px);
          width: 100%;
        }
      }
    }

    @media (max-width: 991.5px) {
      .sidebar {
        max-width: 100%;
        width: 100%;
      }
    }
  }

  .post-wrapper {
    .post-header {
      border-bottom: 1px solid #333;
      padding-bottom: 15px;
      margin-bottom: 20px;

      .post-title {
        font-size: 36px;
        color: var(--primary);
        font-family: Montserrat, Arial, Helvetica, sans-serif;
        font-weight: 700;
        line-height: 1;
        font-style: normal;
        margin-bottom: 20px;
      }

      .post-published {
        display: inline-block;
        color: var(--secondary);
        font-size: 16px;
        font-weight: 600;
      }
    }
  }

  .post-content {
    p {
      font-size: 16px;
      line-height: 1.8;
    }
  }

  .post-sidebar {
    .download-button {
      text-align: center;
      background-color: #417ed9;
      padding: 15px;
      margin-bottom: 30px;
      border: 1px solid #417ed9;
      display: block;
      transition: all 300ms ease;
      color: #fff;
      font-size: 18px;
      font-weight: 600;

      &:hover {
        background-color: var(--primary);
      }
    }

    .post-img img {
      margin-bottom: 10px;
      border: 1px solid var(--primary);
      width: 100%;
      max-width: 100%;
      height: auto;
      vertical-align: top;
    }
  }

  .related-posts-wraper h4 {
    background-color: #edeff0;
    border-style: solid;
    border-width: 15px 15px 15px 16px;
    border-color: #edeff0;
    color: #000;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.75em;
    letter-spacing: 0.5px;
    margin-top: 1.33em;
    margin-bottom: 1.33em;
    font-family: proxima-nova, sans-serif !important;
    font-style: normal;
  }

  .related-posts h2 {
    color: #696a6d;
    font-size: 22px;
    font-family: Montserrat, Arial, Helvetica, sans-serif;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0px;
    font-style: normal;
    margin-bottom: 20px;
  }

  .related-posts-list {
    list-style: none;
    padding-left: 0;

    .related-posts-title:not(:last-child) {
      margin-bottom: 20px;
    }
    .related-posts-title .related-posts-title-link {
      color: #337ab7;
      font-size: 18px;
      font-weight: 600;
      line-height: 1.6;
    }
  }
`;

import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import HorizontalArticle from "../../components/horizontal-article/HorizontalArticle";
// images
import articlePlaceholder from "../../assets/images/comment-letters-placeholder.jpg";
import { useParams } from "react-router-dom";
import axios from "axios";

const FilteredPost = () => {

  const { category, search } = useParams();
  console.log('search', search)

  const [posts, setPosts] = useState([]);
  useEffect(() => {
    var data = {
      "search" : search,
      "initiative": [process.env.REACT_APP_INITIATIVE]
    }
    axios.post(`${process.env.REACT_APP_API_URL}/resource/search`,data).then((response) => {
      setPosts(response?.data?.data ? response.data.data :[]);
    });
  }, []);

  return (
    <section className="article-category py-6 pb-7">
      <Container>
        mm <h2 className="text-center text-capitalize">{ search?.toLowerCase()?.replace(/-/g,' ')}</h2>
        <p
          className="text-center mb-6"
          style={{ maxWidth: "750px", marginLeft: "auto", marginRight: "auto" }}
        >
          On behalf of the Community Oncology Alliance (“COA”), we are
          submitting our concerns regarding the Enhancing Oncology Model (“EOM”)
          to the leadership at the Centers for Medicare & Medicaid Services
          (“CMS”) and Center for Medicare and Medicaid Innovation (“CMMI”).
        </p>
        <hr style={{ borderColor: "#173372" }} />
        <div className="articles mt-6">
        { posts?.map((post, index) => {
                return <HorizontalArticle
                // target={category ? `/${category}/${slug}/${post?.slug}` :`/${slug}/all/${post?.slug}`}
                target={`/all/slug/${post?.slug}`}
                image={post?.feature_image ? post?.feature_image : articlePlaceholder}
                title={post.title}
               // date={post.created_at}
                 date={post.date ? new Date(post.date).toLocaleDateString('en-US',{ day: 'numeric',month: 'short',year: 'numeric'}):''}
                excerpt={post.short_description}
              />
            })}
          <div className="text-center">
            <Button color="secondary">LOAD MORE</Button>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default FilteredPost;

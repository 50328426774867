import React from "react";
import { Download, ExternalLink, Play } from "react-feather";
import { Link } from "react-router-dom";
import { Button, Col, Container, Row } from "reactstrap";
import styled from "styled-components";
import parse from "html-react-parser";

// images
import resourceIcon from "../../../../assets/images/resource-icon.svg";
import placeholderImage from "../../../../assets/images/resource-placeholder.jpg";

const RecommendedVideos = (props) => {
  const { post, isAuthenticated, relatedPosts } = props;
  return (
    <PageTemplate id="page-wrapper" className="pb-3 pt-3 pt-lg-6 pb-lg-6">
      <Container className="mb-4 mb-lg-5">
        <Row className="title-container ">
          <Col
            md="12"
            lg={post?.file_downloadable ? "8" : "12"}
            xl={post?.file_downloadable ? "9" : "12"}
            className="title-warpper "
          >
            <h1 className="mb-0">{post.title}</h1>
          </Col>
          {post?.file_downloadable ? (
            <Col sm="6" md="5" lg="4" xl="3" className="mt-3 mt-lg-0">
              <a href={post.file_downloadable} download target={"_blank"}>
                <Button
                  color="secondary"
                  size=""
                  block
                  tag="a"
                  download
                  outline
                  className="mb-40 d-flex align-items-center justify-content-center"
                >
                  DOWNLOAD <Download size="13" className="ml-1 stroke-width-3px" />
                </Button>
              </a>
            </Col>
          ) : (
            ""
          )}
        </Row>
        <hr />
        <div class="meta d-flex align-items-center flex-wrap">
          {post?.date ? (
            <div className="date mr-3">
              {new Date(post.date).toLocaleDateString("en-US", {
                day: "numeric",
                month: "short",
                year: "numeric",
              })}
            </div>
          ) : (
            ""
          )}
          <div className="category">
            <Link to={"/" + post?.categories_slugs?.[0]?.slug} className="sub-category">
              {post?.categories_slugs?.[0]?.name}
            </Link>
            {" | "}
            <Link
              to={"/" + post?.categories_slugs?.[0]?.slug + "/" + post?.categories_slugs?.[1]?.slug}
              className="sub-category"
            >
              {" "}
              {post?.categories_slugs?.[1]?.name}{" "}
            </Link>
            {""}
          </div>
        </div>
        <hr className="mb-0" />
      </Container>
      <Container>
        <div className="mb-4 mb-lg-5 content-wrapper">
          {post?.video_url ? (
            <div className="video-wrapper mb-3 mb-lg-5">
              <video
                poster={post?.video_thumbnail_url}
                src={post?.video_url}
                className="rounded overflow-hidden"
                controls
                muted
                width={"100%"}
              />
            </div>
          ) : (
            ""
          )}

          {/* <div className="content mt-5">{parse(post.short_description)}</div>
          <hr /> */}
          <div className="content mt-3 mt-lg-5">{parse(post.body)}</div>
        </div>

        <SidebarWrapper>
          <div className="recommended-resources rounded">
            <h3 className="mb-3">Recommended Videos</h3>
            <Row>
              {relatedPosts?.map((related_post, index) => {
                if (index > 2) {
                  return;
                }
                const postUrl =
                  "/" +
                  related_post?.categories_slugs?.[0]?.slug +
                  "/" +
                  related_post?.categories_slugs?.[1]?.slug +
                  "/" +
                  related_post.slug;
                return (
                  <Col xl="3" md="4" sm="6" className="mb-2">
                    <Link to={postUrl} className="video-item">
                      <video
                        poster={related_post?.video_thumbnail_url}
                        src={related_post?.video_url + "#t=1.5"}
                        className="rounded overflow-hidden"
                        controls
                        muted
                        width={"100%"}
                      />
                      <div className="icon">
                        <Play size={"30"} />
                      </div>
                    </Link>
                  </Col>
                );
              })}
            </Row>
          </div>
        </SidebarWrapper>
      </Container>
    </PageTemplate>
  );
};

export default RecommendedVideos;

export const PageTemplate = styled.section`
  background: linear-gradient(180deg, #d5e5ef 0%, rgba(213, 229, 239, 0) 40%);

  hr {
    color: #d1d3d4;
  }

  .content {
    & > :last-child {
      margin-bottom: 0;
    }
  }

  .date {
    color: #898a8d;
    font-family: var(--primaryFont);
    font-size: 12px;
    line-height: 18px;
    font-weight: 800;
    letter-spacing: 0.75px;
  }

  .category {
    color: #4180be;
    font-family: var(--primaryFont);
    font-size: 12px;
    line-height: 18px;
    font-weight: 800;
    letter-spacing: 0.75px;
  }
`;

export const SidebarWrapper = styled.aside`
  .mb-40 {
    margin-bottom: 40px;
  }

  .recommended-resources {
    background-color: #f3f3f3;
    padding: 25px 40px 16px 40px;

    svg {
      stroke-width: 2.6px;
    }

    .video-item {
      position: relative;
      display: block;
      aspect-ratio: 16/9;

      img {
        aspect-ratio: 16/9;
        object-fit: cover;
      }
      .icon {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        /* background-color: rgba(0, 0, 0, 0.5); */

        svg {
          fill: #fff;
          stroke: #fff;
        }
      }

      video {
        height: 100%;
      }
    }
  }

  h6 {
    margin-bottom: 30px;
  }
`;

import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Label,
  Card,
  CardBody,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
  FormGroup,
  CardHeader,
} from "reactstrap";
import {
  Autocomplete,
  Box,
  Grid,
  TextField,
  Typography,
  Popper
} from '@mui/material'
// import Icon from 'src/@core/components/icon'
import Loading from "../../../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { AvForm, AvInput, AvGroup, AvField } from "availity-reactstrap-validation-safe";
import {
  valSwalSuccess,
  valSwalFailure,
  swalalertWithMsg,
  currentRole,
} from "../../../dev-config/global";
import InputMask from "react-input-mask";
import {
  currentUser,
  updateUserPassword,
  updateUserProfile
} from "../../../views/user/store/action/index.js";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import ProfileHeader from "./ProfileHeader.js";
import styled from "styled-components";
import { Home, MapPin, Plus, Trello } from "react-feather";

export const ProfileComponent = () => {
  
  const passwordForm = useRef();
  
  const { user } = useAuth0();

  const[formData, setFormData] = useState({
    // Step 1
    user_id: "", 
    first_name: "", 
    last_name: "",
    work_phone: "",
    work_phone_ext: "", 
    zipcode: "",
    register_type_id: "",
    other_register_type: "", 
    register_account_type_id: "", 
    other_register_account_type: "", 
    updated_by: "",
    practice_group_name: "",
    // Step 2
    register_subscriptions: "",
    // Step 3
    practice_id: null, 
    practice_group_id: null, 
    job_title: "", 
    work_address: "", 
    work_city: "", 
    work_state: "", 
    work_zipcode: ""
  });

// Subscriptions
const [ccList, setCcList] = useState([])
const [allCcListIds, setAllCcListIds] = useState([])
const [selectAllOptionId, setSelectAllOptionId] = useState('670d2406ad6d228438ac0c1a')

useEffect(() => {
  var payload = { search: "", type: "Subscription" };
  axios.post(`${process.env.REACT_APP_API_URL}/register-dropdown/cc-list-options`, payload).then((response) => {
    if(response.data){
      setCcList(response.data.data);
      setAllCcListIds(response?.data?.data?.map(item => item._id))
      // console.log('allCcListIds ', allCcListIds)
    }
  });
}, [])
// Subscriptions

// Register Type
const [registrationTypeOptions, setRegistrationTypeOptions] = useState([])
const [registrationTypeSearch, setRegistrationTypeSearch] = useState('')
useEffect(() => {
  var payload = {search: ''}
  // if(userProfile){
    axios.post(`${process.env.REACT_APP_API_URL}/register-dropdown/register-type-options`, payload).then((response) => {
      if(response.data){
        setRegistrationTypeOptions(response.data.data);
      }
    });
  // }
}, [registrationTypeSearch])
// Register Type

// Account Type
const [accountTypeOption, setAccountTypeOption] = useState([])
const [accountTypeSearch, setAccountTypeSearch] = useState('')
useEffect(() => {
  var payload = { register_type_id: formData?.register_type_id }
  if(userProfile && formData?.register_type_id){
    axios.post(`${process.env.REACT_APP_API_URL}/register-dropdown/account-type-options`, payload).then((response) => {
      if(response.data){
        setAccountTypeOption(response.data.data);
      }
    });
  }
}, [accountTypeSearch, formData?.register_type_id])
// Account Type

// Practice Group
const [practiceGroup, setPracticeGroup] = useState([])
const [practiceGroupSearch, setPracticeGroupSearch] = useState('')
useEffect(() => {
  var payload = { search: practiceGroupSearch, count: practiceGroupSearch ? 30 : 0 }
  if(userProfile){
    axios.post(`${process.env.REACT_APP_API_URL}/practice-group/dropdown-options`, payload).then((response) => {
      if(response.data){
        setPracticeGroup(response.data.data);
      }
    });
  }
}, [practiceGroupSearch])
// Practice Group

// Practice Location
const [practiceLocationOption, setPracticeLocationOption] = useState([])
const [practiceLocationSearch, setPracticeLocationSearch] = useState('')
useEffect(() => {
  var payload = { search: practiceLocationSearch, count: formData?.practice_group_id ? 30 : 0, practice_group_id: formData?.practice_group_id }
  if(userProfile){
    axios.post(`${process.env.REACT_APP_API_URL}/practice/dropdown-options`, payload).then((response) => {
      if(response.data){
        setPracticeLocationOption(response.data.data);
      }
    });
  }
}, [practiceLocationSearch, formData?.practice_group_id])
// Practice Location

// Get User Profile
const [userProfile, setUserProfile] = useState(null);
const dispatch = useDispatch();
useEffect(() => {
  if (user && user.sub) {
    dispatch(currentUser(user?.sub.replace("auth0|", "")));
    axios.get(`${process.env.REACT_APP_API_URL}/user/${user?.sub.replace("auth0|", "")}`).then((response) => {
      if(response.data)
        setUserProfile(response.data.data);
    });
  }
  return () => {
    dispatch({
      type: "CURRENT_USER",
      data: null,
    });
  };
}, [user]);
// Get User Profile

// console.log('userProfile ', userProfile)

  useEffect(() => {
    if(userProfile){
      setFormData({
        // Step 1
        user_id: userProfile?._id, 
        first_name: userProfile?.user_metadata?.first_name  || null, 
        last_name: userProfile?.user_metadata?.last_name  || null,
        work_phone: userProfile?.contact?.work_phone  || null,
        work_phone_ext: userProfile?.contact?.work_phone_ext || null, 
        zipcode: userProfile?.contact?.addresses?.other?.zipcode || null,
        register_type_id: userProfile?.register_type_id || null,
        other_register_type: userProfile?.other_register_type || null, 
        register_account_type_id: userProfile?.register_account_type_id || null, 
        other_register_account_type: userProfile?.other_register_account_type || null, 
        updated_by: `${userProfile?.user_metadata?.first_name || ''} ${userProfile?.user_metadata?.last_name || ''}`,
        practice_group_name: userProfile?.practice_group_name || null, 
        // Step 2
        register_subscriptions: userProfile?.user_cc_lists,
        // Step 3
        practice_id: userProfile?.practice_id || null, 
        practice_group_id: userProfile?.practice_group_id || null, 
        job_title: userProfile?.job_title || null, 
        work_address: userProfile?.contact?.addresses?.work?.address || null, 
        work_city: userProfile?.contact?.addresses?.work?.city || null, 
        work_state: userProfile?.contact?.addresses?.work?.state || null, 
        work_zipcode: userProfile?.contact?.addresses?.work?.zipcode || null
      })
      setPracticeGroupSearch(userProfile?.practice_group_name)
    }
  }, [userProfile]);

  // console.log('formData ', formData)

  const handleSubscriptionChange = item => {
    if (item?._id === selectAllOptionId) {
      setFormData(prevFormData => ({
        ...prevFormData,
        register_subscriptions: prevFormData?.register_subscriptions?.includes(item._id) ? [] : allCcListIds
      }))
    } else {
      setFormData(prevFormData => {
        var updatedCcLists = prevFormData?.register_subscriptions?.includes(item._id)
          ? prevFormData.register_subscriptions.filter(id => id !== item._id)
          : [...prevFormData.register_subscriptions, item._id]
        if (
          updatedCcLists.includes(selectAllOptionId) &&
          updatedCcLists.length == allCcListIds.length - 1
        ) {
          updatedCcLists = updatedCcLists.filter(id => id !== selectAllOptionId)
        } else if (updatedCcLists.length == allCcListIds.length - 1) {
          updatedCcLists = allCcListIds
        } else {
          updatedCcLists = updatedCcLists.filter(id => id !== selectAllOptionId)
        }
        return {
          ...prevFormData,
          register_subscriptions: updatedCcLists
        }
      })
    }
  }

  const handleInputChange = (value, key) => {
    setFormData(prev => ({
      ...prev,
      [key]: value
    }))
  }

  const onSubmit = (event, errors, values) => {
    
    console.log('errors ', errors)
    var payload = formData;

    // Step 1
    let required_key = ['first_name', 'last_name', 'work_phone', 'register_type_id', 'register_account_type_id', 'zipcode', 'job_title', 'practice_group_id','practice_id'];

    if(formData?.register_type_id == "66ec2b76ac213c4e30e1cbd5"){
      required_key.push('other_register_type')
    }
    if(formData?.register_account_type_id == "66ec2f0ef716b650f25efe04"){
      required_key.push('other_register_account_type')
    }

    let required_key_value_pair = {
      first_name: 'first name',
      last_name: 'last name',
      work_phone: 'phone number',
      register_type_id: 'register type',
      register_account_type_id: 'account type(role)',
      zipcode: 'zipcode',
      other_register_type: 'other register type',
      other_register_account_type: 'other register account type',
      job_title: 'job title',
      practice_group_id: 'organization',
      practice_id:"Organization Location",
    }

    if(errors && errors.length > 0){
      valSwalFailure("Error!", "Please enter valid " + required_key_value_pair[errors[0]]);
      return false;
    }

    for (let i = 0; i < required_key.length; i++) {
      if (!payload[required_key[i]]) {
        valSwalFailure('Error', `Please enter a valid ${required_key_value_pair[required_key[i]]}`)
        return false
      }
    }

    // Step 2
    if (payload?.register_subscriptions?.length <= 0) {
      valSwalFailure('Error', `Please select at least one email subscription before saving..`)
      return false
    }

    if (payload?.job_title.replaceAll(' ', '').length<=0) {
    valSwalFailure('Error', `Please enter a valid Job Title`)
    return false
    }
    
    if (payload?.first_name.replaceAll(' ', '').length<=0) {
    valSwalFailure('Error', `Please enter a valid first name`)
    return false
    }

    if (payload?.last_name.replaceAll(' ', '').length<=0) {
    valSwalFailure('Error', `Please enter a valid last name`)
    return false
    }

    var isValidZip = /^(?!00000)\d{5}$/.test(payload?.zipcode);
    if(!isValidZip){
    valSwalFailure('Error', `Please enter a valid Zipcode`)
    return false
    }


    var isValidJobtitle = /^[a-zA-Z][0-9a-zA-Z .,'-]*$/.test(payload?.job_title);
    var isValidFirstName = /^[a-zA-Z][0-9a-zA-Z .,'-]*$/.test(payload?.first_name);
    var isValidLastName = /^[a-zA-Z][0-9a-zA-Z .,'-]*$/.test(payload?.last_name);

    if(!isValidJobtitle){
    valSwalFailure('Error', `Please enter a valid Job Title`)
    return false
    }

    if(!isValidFirstName){
    valSwalFailure('Error', `Please enter a valid First Name`)
    return false
    }

    if(!isValidLastName){
    valSwalFailure('Error', `Please enter a valid Last Name`)
    return false
    }

    swalalertWithMsg(true, "Please wait...");
    dispatch(updateUserProfile(payload))
      .then((res) => {
        valSwalSuccess("Success", "Profile updated succesfully!");
      })
      .catch((err) => {
        valSwalFailure(
          "Error",
          err?.response?.data?.message
            ? err.response.data.message
            : "Something went wrong, please try again!"
        );
      });
  };

  const handleUpdatePassword = async (e, errors, values) => {
    if (errors.length > 0) {
      valSwalFailure("Error", "Please enter " + errors?.[0]?.replace('_', ' '));
      return false;
    }
    if (values.password != values.confirm_password) {
      valSwalFailure("Error", "New password and Confirm password should be same");
      return false;
    }
    swalalertWithMsg(true, "Please wait...");
    dispatch(updateUserPassword(values))
      .then((res) => {
        // passwordForm.current.reset()
        document.getElementById("password").value = "";
        document.getElementById("confirm_password").value = "";
        valSwalSuccess("Success", "Password updated succesfully!");
      })
      .catch((err) => {
        if (err?.response?.data?.message == "The user does not exist.") {
          document.getElementById("password").value = "";
          document.getElementById("confirm_password").value = "";
          valSwalSuccess("Success", "Password updated succesfully!");
        } else {
          valSwalFailure(
            "Error",
            err?.response?.data?.message
              ? err.response.data.message
              : "Something went wrong, please try again!"
          );
        }
      });
  };


const highlightText = (text, highlight) => {
  if (!highlight?.trim()) return text
  const highlightStyle = {
    fontWeight: 'bold',
    color: '#133d8d' // Primary text color
    // textTransform: 'uppercase' // Optional: Transform text to uppercase
  }

  const parts = text.split(new RegExp(`(${highlight})`, 'gi'))
  return parts.map((part, index) =>
    part.toLowerCase() === highlight.toLowerCase() ? (
      <span key={index} style={highlightStyle}>
        {part}
      </span>
    ) : (
      part
    )
  )
}

const splitLocation = props => {
  const { city, state, zip_code } = props
  const parts = []
  if (city) parts.push(city)

  if (state) parts.push(state)

  if (zip_code) parts.push(zip_code)

  return parts.join(', ')
}

  const disabled = (userProfile?.email == 'tech@firesky.cc');

  return (
    <Container className="my-6">
      <ProfileHeader userProfile={userProfile} formData={formData}/>
      <Card className="mb-0 mt-2" style={{ borderBlock: "none", border: "none" }}>
        <CardBody className="p-0 mt-4">
          <Row>
            <Col sm="12">
              <AvForm onSubmit={onSubmit} disabled={disabled}>
                <Row className="border mx-0">

                  {/* Step 1 */}
                  <Col md="4" sm="12" className="p-0">
                    <div>
                      <CardHeader tag={"h6"} style={{ fontWeight: "600" }}>
                        Organization Information
                      </CardHeader>
                      <CardBody>

                        {/* Job Title */}
                        <AvGroup>
                          <Label className="form-label" for="job_title">
                            Job Title <span className="text-danger">*</span>
                          </Label>
                          <AvInput
                            type="text"
                            id="job_title"
                            name="job_title"
                            placeholder="Job Title"
                            value={formData?.job_title}
                            onChange={e => handleInputChange(e.target.value, 'job_title')}
                          />
                        </AvGroup>

                        {/* Organization */}
                        <AvGroup>
                          <Label for="organization_name">
                            Organization <span className="text-danger">*</span>
                          </Label>
                          <Autocomplete
                            disabled={disabled}
                            size={'small'}
                            value={practiceGroup.find(option => option._id === formData?.practice_group_id) || null}
                            options={practiceGroup}
                            // inputValue={practiceGroupSearch}
                            id='practice-group'
                            PopperComponent={StyledPopper}
                            noOptionsText='Sorry! We couldn’t find anything. Type to find.'
                            getOptionLabel={option => option.name || ''}
                            renderInput={params => <TextField {...params} placeholder='Search' />}
                            onInputChange={(event, newInputValue) => setPracticeGroupSearch(newInputValue)}
                            onChange={(e, selectedOption, reason, details) => {
                                handleInputChange(selectedOption?._id, 'practice_group_id');
                                handleInputChange(selectedOption?.name, 'practice_group_name');
                                handleInputChange(null, 'practice_id');
                                handleInputChange('', 'work_address');
                                handleInputChange('', 'work_city');
                                handleInputChange('', 'work_state');
                                handleInputChange('', 'work_zipcode');
                            }}
                            renderOption={(props, option) => {
                              const { key, ...optionProps } = props
                              return (
                                <li key={key} {...optionProps}>
                                  <Grid container sx={{ alignItems: 'center' }}>
                                    <Grid item sx={{ display: 'flex', width: 30, color: 'primary.main' }}>
                                      {/* <Icon icon='bx:buildings' /> */}
                                      <Trello />
                                    </Grid>
                                    <Grid item sx={{ width: 'calc(100% - 30px)', wordWrap: 'break-word' }}>
                                      <Typography fontSize={'14px'} key={option._id} lineHeight={1.3} fontWeight={500}>
                                        {highlightText(option.name, practiceGroupSearch)}
                                      </Typography>
                                      <Typography variant='subtitle2' color='text.secondary'>
                                        {splitLocation(option)}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </li>
                              )
                            }}
                          />
                        </AvGroup>

                        {/* Organization Location */}
                        <AvGroup>
                          <Label for="organization_name">
                            Organization Location <span className="text-danger">*</span>
                          </Label>
                          <Autocomplete
                            disabled={disabled}
                            size={'small'}
                            value={practiceLocationOption.find(option => option._id === formData?.practice_id) || null}
                            options={practiceLocationOption}
                            id='practice-location'
                            PopperComponent={StyledPopper}
                            noOptionsText='Sorry! We couldn’t find anything. Type to find.'
                            getOptionLabel={option => option.name || ''}
                            renderInput={params => <TextField {...params} placeholder='Search' />}
                            onInputChange={(event, newInputValue) => setPracticeLocationSearch(newInputValue)}
                            onChange={(e, selectedOption, reason, details) => {
                                if(reason == 'clear'){
                                  handleInputChange(selectedOption?._id, 'practice_id');
                                  handleInputChange(selectedOption?.name, 'practice_name');
                                  handleInputChange('', 'work_address');
                                  handleInputChange('', 'work_city');
                                  handleInputChange('', 'work_state');
                                  handleInputChange('', 'work_zipcode');
                                }else{
                                  handleInputChange(selectedOption?._id, 'practice_id');
                                  handleInputChange(selectedOption?.name, 'practice_name');
                                  handleInputChange(selectedOption?.address1, 'work_address');
                                  handleInputChange(selectedOption?.city, 'work_city');
                                  handleInputChange(selectedOption?.state, 'work_state');
                                  handleInputChange(selectedOption?.zip_code, 'work_zipcode');
                                }
                            }}
                            renderOption={(props, option) => {
                              const { key, ...optionProps } = props
                              return (
                                <li key={key} {...optionProps}>
                                  <Grid container sx={{ alignItems: 'center' }}>
                                    <Grid item sx={{ display: 'flex', width: 30, color: 'primary.main' }}>
                                      {/* <Icon icon='mingcute:location-2-line' /> */}
                                      <MapPin/>
                                    </Grid>
                                    <Grid item sx={{ width: 'calc(100% - 30px)', wordWrap: 'break-word' }}>
                                      <Typography fontSize={'14px'} key={option._id} lineHeight={1.3} fontWeight={500}>
                                        {highlightText(option.name, practiceLocationSearch)}
                                      </Typography>
                                      <Typography variant='subtitle2' color='text.secondary'>
                                        {splitLocation(option)}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </li>
                              )
                            }}
                          />
                        </AvGroup>

                        {/* Work Address */}
                        <FormGroup>
                          <Label for="work_address">Work Address</Label>
                          <AvInput
                            name="work_address"
                            id="work_address"
                            placeholder=" "
                            value={formData?.work_address}
                            onChange={e => handleInputChange(e.target.value, 'work_address')}
                            readOnly
                          />
                        </FormGroup>

                        {/* Work City */}
                        <FormGroup>
                          <Label for="work_city">Work City</Label>
                          <AvInput
                            name="work_city"
                            id="work_city"
                            placeholder=" "
                            value={formData?.work_city}
                            onChange={e => handleInputChange(e.target.value, 'work_city')}
                            readOnly
                          />
                        </FormGroup>

                        {/* Work State */}
                        <FormGroup>
                          <Label for="work_state">Work State</Label>
                          <AvInput
                            name="work_state"
                            id="work_state"
                            placeholder=" "
                            value={formData?.work_state}
                            onChange={e => handleInputChange(e.target.value, 'work_state')}
                            readOnly
                          />
                        </FormGroup>

                        {/* Work Zipcode */}
                        <FormGroup>
                          <Label for="work_zipcode">Work Zipcode</Label>
                          <AvInput
                            name="work_zipcode"
                            id="work_zipcode"
                            placeholder=" "
                            value={formData?.work_zipcode}
                            onChange={e => handleInputChange(e.target.value, 'work_zipcode')}
                            readOnly
                          />
                        </FormGroup>
                      </CardBody>
                    </div>
                  </Col>

                  {/* Step 2 */}
                  <StyledCol md="4" sm="12" className="p-0 border-left border-right">
                    <div>
                      <CardHeader tag={"h6"} style={{ fontWeight: "600" }}>
                        Personal Information
                      </CardHeader>
                      <CardBody>

                        {/* First Name */}
                        <AvGroup>
                          <Label className="form-label" for="name">
                            First Name <span className="text-danger">*</span>
                          </Label>
                          <AvInput
                            type="text"
                            id="first_name"
                            name="first_name"
                            placeholder="Name"
                            value={formData?.first_name}
                            onChange={e => handleInputChange(e.target.value, 'first_name')}
                          />
                        </AvGroup>

                        {/* Last Name */}
                        <AvGroup>
                          <Label className="form-label" for="last_name">
                            Last Name <span className="text-danger">*</span>
                          </Label>
                          <AvInput
                            type="text"
                            id="last_name"
                            name="last_name"
                            placeholder="Last Name"
                            value={formData?.last_name}
                            onChange={e => handleInputChange(e.target.value, 'last_name')}
                          />
                        </AvGroup>

                        {/* Work Phone */}
                        <FormGroup>
                          <Label for="work_phone">Phone <span className="text-danger">*</span></Label>
                          <InputGroup className="input-group-merge">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>US (+1)</InputGroupText>
                            </InputGroupAddon>
                            <AvInput
                              id={`work_phone`}
                              name={`work_phone`}
                              type="tel"
                              mask="(999) 999-9999"
                              tag={[Input, InputMask]}
                              style={{ paddingLeft: ".5rem" }}
                              value={formData?.work_phone}
                              onChange={e => handleInputChange(e.target.value, 'work_phone')}
                              placeholder="ex: 123 456 7890"
                              required
                            />
                          </InputGroup>
                        </FormGroup>

                        {/* Work Phone Extension */}
                        <AvGroup>
                          <Label className="form-label" for="work_phone_ext">
                            Extension
                          </Label>
                          <AvInput
                            type="text"
                            id="work_phone_ext"
                            name="work_phone_ext"
                            placeholder=""
                            value={formData?.work_phone_ext}
                            onChange={e => handleInputChange(e.target.value, 'work_phone_ext')}
                          />
                        </AvGroup>

                        {/* Registration Type */}
                        <AvGroup>
                          <Label className="form-label" for="work_phone_ext">
                          Registration Type  <span className="text-danger">*</span>
                          </Label>
                          <Autocomplete
                          color="secondary"
                            disabled={disabled}
                            size={'small'}
                            id='register_type_id'
                            options={registrationTypeOptions}
                            value={registrationTypeOptions.find(option => option._id === formData?.register_type_id) || null}
                            getOptionLabel={option => option.name || ''}
                            renderInput={params => <TextField {...params} placeholder='Select' />}
                            onInputChange={(event, newInputValue) => setRegistrationTypeSearch(newInputValue)}
                            onChange={(event, selectedOption, reason) => {
                              handleInputChange(selectedOption?._id, 'register_type_id');
                              handleInputChange(null, 'other_register_type');
                              handleInputChange(null, 'register_account_type_id');
                              handleInputChange(null, 'other_register_account_type');
                            }}
                            renderOption={(props, option) => (
                              <li {...props}>
                                <Grid container sx={{ alignItems: 'center' }}>
                                  <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                                    <Box component='span'>{highlightText(option?.name, registrationTypeSearch)}</Box>
                                  </Grid>
                                </Grid>
                              </li>
                            )}
                          />
                        </AvGroup>

                        {/* Other Registration Type */}
                        {('66ec2b76ac213c4e30e1cbd5' == formData?.register_type_id) ? 
                        <AvGroup >
                          <Label className="form-label" for="other_register_type">
                          Other Registration Type <span className="text-danger">*</span>
                          </Label>
                          <AvInput
                            type="text"
                            id="other_register_type"
                            name="other_register_type"
                            onChange={e => handleInputChange(e.target.value, 'other_register_type')}
                            value={formData?.other_register_type}
                          />
                        </AvGroup>
                        : ''}

                        {/* Account Type */}
                        <AvGroup>
                          <Label className="form-label" for="work_phone_ext">
                          Account Type  <span className="text-danger">*</span>
                          </Label>
                          <Autocomplete
                            disabled={disabled}
                            size={'small'}
                            id='register_account_type_id'
                            options={accountTypeOption}
                            value={accountTypeOption.find(option => option._id === formData?.register_account_type_id) || null}
                            getOptionLabel={option => option.name || ''}
                            renderInput={params => <TextField {...params} placeholder='Select' />}
                            onInputChange={(event, newInputValue) => {
                              setAccountTypeSearch(newInputValue)
                            }}
                            onChange={(e, selectedOption, reason) => {
                              if(reason == 'clear'){
                                handleInputChange(null, 'other_register_account_type')
                              }
                              handleInputChange(selectedOption?._id, 'register_account_type_id')
                            }}
                            renderOption={(props, option) => {
                              const { key, ...optionProps } = props
                              return (
                                <li key={key} {...optionProps}>
                                  <Grid container sx={{ alignItems: 'center' }}>
                                    <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                                      <Box key={option._id} component='span'>
                                        {highlightText(option?.name, accountTypeSearch)}
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </li>
                              )
                            }}
                          />
                        </AvGroup>

                        {/* Other Account Type */}
                        {('66ec2f0ef716b650f25efe04' == formData?.register_account_type_id) ? 
                        <AvGroup hidden={'66ec2f0ef716b650f25efe04' != formData?.register_account_type_id}>
                          <Label className="form-label" for="other_register_account_type">
                          Other Account Type (Other Role) <span className="text-danger">*</span>
                          </Label>
                          <AvInput
                            type="text"
                            id="other_register_account_type"
                            name="other_register_account_type"
                            onChange={e => handleInputChange(e.target.value, 'other_register_account_type')}
                            value={formData?.other_register_account_type}
                          />
                        </AvGroup>
                        : ''}

                        {/* Zipcode */}
                        <AvGroup>
                          <Label className="form-label" for="zipcode">
                            Zipcode <span className="text-danger">*</span>
                          </Label>
                          <AvInput
                            type="text"
                            id="zipcode"
                            name="zipcode"
                            maxLength={5}
                            minLength={5}
                            placeholder=""
                            value={formData?.zipcode}
                            onChange={e => handleInputChange(e.target.value, 'zipcode')}
                            onKeyPress ={ (event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault()
                              }
                            }}
                          />
                        </AvGroup>

                      </CardBody>
                    </div>
                  </StyledCol>

                  {/* Step 3 */}
                  <Col md="4" sm="12" className="p-0">
                    <div>
                      <CardHeader
                        tag={"h6"}
                        style={{ fontWeight: "600" }}
                      >
                        Email Subscriptions
                      </CardHeader>
                      <CardBody>
                        { formData?.register_subscriptions && ccList?.length > 0 ? 
                        <FormGroup className="mb-0">
                        {ccList?.map((item, index) => {
                            return (
                              <AvGroup check className="mb-1">
                                <Label check key={item._id}>
                                  <input 
                                    disabled={disabled}
                                    key={`key-${item._id}`}
                                    type="checkbox" 
                                    name="checkbox" 
                                    checked={formData?.register_subscriptions?.includes(item._id)}
                                    onChange={() => handleSubscriptionChange(item)} />
                                    {"  "} {item.name} 
                                    {formData?.register_subscriptions?.includes(item._id) == true ? '' : ''} {/* Dont remove */}
                                </Label>
                              </AvGroup>
                            )
                          })}
                        </FormGroup>
                        : ''}
                      </CardBody>
                    </div>
                  </Col>

                </Row>
                {!disabled ?
                <div className="text-right mt-4">
                  <Button color="btn btn-primary btn-sm" style={{ width: "max-content" }}>
                    Submit
                  </Button>
                </div>
                 : ''}
              </AvForm>
            </Col>
          </Row>
        </CardBody>
      </Card>

      {/* Change Password */}
      <Card border className="mt-4">
        <CardBody className="p-0  bg-light p-4">
          <AvForm
            onSubmit={handleUpdatePassword}
            ref={passwordForm}
          >
            <h4 className="mt-1">Change Password</h4>
            <Row>
              <Col md="4" sm="12">
                  <AvInput name="user_id" id="user_id" value={userProfile?._id} hidden />
                <FormGroup>
                  <Label for="password">New Password</Label>
                  <AvInput
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Enter New Password"
                    required
                  />
                </FormGroup>
              </Col>
              <Col md="4" sm="12">
                <FormGroup>
                  <Label for="confirm_password">Confirm New Password</Label>
                  <AvInput
                    type="password"
                    name="confirm_password"
                    id="confirm_password"
                    placeholder="Confirm New Password"
                    required
                  />
                </FormGroup>
              </Col>
              <Col sm="12" className="mb-1 ">
                <Button
                  type="update_password"
                  name="update_password"
                  color="primary"
                  className="btn-sm"
                >
                  Update Password
                </Button>
              </Col>
            </Row>
          </AvForm>
        </CardBody>
      </Card>
      {/* Change Password */}

    </Container>
  );
};

export default withAuthenticationRequired(ProfileComponent, {
  onRedirecting: () => <Loading />,
});

export const StyledCol = styled(Col)`
  @media (max-width: 768px) {
    border: none !important;
  }
`;

const StyledPopper = styled(Popper)(({ theme }) => ({
  boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12)'
}));
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row } from "reactstrap";
import styled from "styled-components";
import { SubHeaderWrapper } from "../../components/GlobalStyles";
// Table //
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import axios from "axios";
import PostTeaser from "../../components/post-teaser/PostTeaser";
import { useParams, Link, useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
// import paginationFactory from "react-bootstrap-table2-paginator";
// import parse from "html-react-parser";

// export const productsGenerator = quantity => {
//   // const items = [];
//   // for (let i = 0; i < quantity; i++) {
//   //   items.push({ standards: i, requirement: `Item name ${i}`, download: 2100 + i, dept: 123444 + i, explanation: `Explanation ${i}` });
//   // }
//   // console.log("items", items)
//   const items = [
//     {
//         "standards": "DRX11-A",
//         "download": "",
//         "explanation": "The governing body/owner forms a Professional Advisory Committee (PAC) with representation fromorganizations comprising professionals with expertise in the care and treatment of oncology clients/patients. The PAC meets atleast once a year, with the first meeting taking place prior to accreditation being awarded. If the specialty pharmacy is part of alarger organization, this entity must also be represented on the PAC."
//     },
//     {
//       "standards": "DRX11-B",
//       "download": "",
//       "explanation": "Written policies and procedures are established and implemented for defining the protocol for chemotherapy drugs, where applicable."
//     },
//     {
//       "standards": "DRX11-C",
//       "download": "",
//       "explanation": "The organization will inform clients/patients of the cost of their medications and provide information about financial assistance programs for obtaining the medication needed for their treatment."
//     },
//     {
//       "standards": "DRX11-D",
//       "download": "",
//       "explanation": "The organization has an accounting system that can track all of its client's/patients’ medications and costs."
//     },
//     {
//       "standards": "DRX11-E",
//       "download": "",
//       "explanation": "Written policies and procedures are established and implemented by the organization in regard to the monitoring of medications using the most cost-effective means."
//     },
//     {
//       "standards": "DRX11-F",
//       "download": "",
//       "explanation": "Written policies and procedures are established and implemented in regard to guidelines for personnel and organizational safety related to exposure to hazardous material, as set forth by OSHA."
//     },
//     {
//       "standards": "DRX11-G",
//       "download": "",
//       "explanation": "Written policies and procedures are established and implemented in regard to additional training required of personnel who care for cancer clients/patients."
//     },
//     {
//       "standards": "DRX11-H",
//       "download": "",
//       "explanation": "Written policies and procedures are established and implemented in regard to handling and dispensing oncology medications."
//     },
//     {
//       "standards": "DRX11-I",
//       "download": "",
//       "explanation": "Written policies and procedures are established and implemented for the disposal of hazardous materials and waste."
//     },
//     {
//       "standards": "DRX11-J",
//       "download": "",
//       "explanation": "The organization monitors the use of physician-ordered medications for chemotherapy treatment."
//     },
//     {
//       "standards": "DRX11-K",
//       "download": "",
//       "explanation": "Written policies and procedures are established and implemented in regard to the education given to the client/patient on the protocols for use of the medication prescribed for their treatment."
//     },
//     {
//       "standards": "DRX11-L",
//       "download": "",
//       "explanation": "Written policies and procedures are established and implemented by the organization in regard to thedocumentation and communication of medication errors including documentation of all healthcare providers involved in theclient's/patient’s care and that medication errors have been reported."
//     },
//     {
//       "standards": "DRX11-M",
//       "download": "",
//       "explanation": "Communication with other healthcare providers related to the provision of oncology medications is documented."
//     },
//     {
//       "standards": "DRX11-N",
//       "download": "",
//       "explanation": "Each client/patient receiving chemotherapy must have personnel available who perform client/patient advocate duties to help him or her with any medication needs and concerns."
//     }
//     ];
//   return items;
// };

// const products = productsGenerator(15);

// const items = [
//   {
//       "standards": "DRX11-A",
//       "download": "",
//       "explanation": "The governing body/owner forms a Professional Advisory Committee (PAC) with representation fromorganizations comprising professionals with expertise in the care and treatment of oncology clients/patients. The PAC meets atleast once a year, with the first meeting taking place prior to accreditation being awarded. If the specialty pharmacy is part of alarger organization, this entity must also be represented on the PAC."
//   },
//   {
//     "standards": "DRX11-B",
//     "download": "<a href='https://coapharmacy.com/achc-pol/DRX-11-B/DRX-11-B.docx'><img src='https://coapharmacy.com/images/word.jpg' width='30'></a> <a href='https://coapharmacy.com/achc-pol/DRX-11-B/DRX-8-1A.docx'><img src='https://coapharmacy.com/images/word.jpg' width='30'></a>",
//     "explanation": "Written policies and procedures are established and implemented for defining the protocol for chemotherapy drugs, where applicable."
//   },
//   {
//     "standards": "DRX11-C",
//     "download": "",
//     "explanation": "The organization will inform clients/patients of the cost of their medications and provide information about financial assistance programs for obtaining the medication needed for their treatment."
//   },
//   {
//     "standards": "DRX11-D",
//     "download": "",
//     "explanation": "The organization has an accounting system that can track all of its client's/patients’ medications and costs."
//   },
//   {
//     "standards": "DRX11-E",
//     "download": "",
//     "explanation": "Written policies and procedures are established and implemented by the organization in regard to the monitoring of medications using the most cost-effective means."
//   },
//   {
//     "standards": "DRX11-F",
//     "download": "",
//     "explanation": "Written policies and procedures are established and implemented in regard to guidelines for personnel and organizational safety related to exposure to hazardous material, as set forth by OSHA."
//   },
//   {
//     "standards": "DRX11-G",
//     "download": "",
//     "explanation": "Written policies and procedures are established and implemented in regard to additional training required of personnel who care for cancer clients/patients."
//   },
//   {
//     "standards": "DRX11-H",
//     "download": "",
//     "explanation": "Written policies and procedures are established and implemented in regard to handling and dispensing oncology medications."
//   },
//   {
//     "standards": "DRX11-I",
//     "download": "",
//     "explanation": "Written policies and procedures are established and implemented for the disposal of hazardous materials and waste."
//   },
//   {
//     "standards": "DRX11-J",
//     "download": "",
//     "explanation": "The organization monitors the use of physician-ordered medications for chemotherapy treatment."
//   },
//   {
//     "standards": "DRX11-K",
//     "download": "",
//     "explanation": "Written policies and procedures are established and implemented in regard to the education given to the client/patient on the protocols for use of the medication prescribed for their treatment."
//   },
//   {
//     "standards": "DRX11-L",
//     "download": "",
//     "explanation": "Written policies and procedures are established and implemented by the organization in regard to thedocumentation and communication of medication errors including documentation of all healthcare providers involved in theclient's/patient’s care and that medication errors have been reported."
//   },
//   {
//     "standards": "DRX11-M",
//     "download": "",
//     "explanation": "Communication with other healthcare providers related to the provision of oncology medications is documented."
//   },
//   {
//     "standards": "DRX11-N",
//     "download": "",
//     "explanation": "Each client/patient receiving chemotherapy must have personnel available who perform client/patient advocate duties to help him or her with any medication needs and concerns."
//   }
//   ];

const Oncology = () => {
  // const [items, setItems] = useState(null);
  // const baseURL = "https://mycoa.io/api";
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const [items, setItems] = useState(null);
  const domain = "login.mycoa.io";

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/page-template/achc-oncology-distinction`)
      .then((response) => {
        setItems(response.data.data);
      });
  }, []);

  const [hasCOPAAccess, setHasCOPAAccess] = useState(false);

  useEffect(() => {
    const getUserMetadata = async () => {
      try {
        const accessToken1 = await getAccessTokenSilently({
          audience: "https://dev-qgf3-cce.us.auth0.com/api/v2/",
          scope: "read:current_user",
        });

        const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`;

        const metadataResponse = await fetch(userDetailsByIdUrl, {
          headers: {
            Authorization: `Bearer ${accessToken1}`,
          },
        });

        const { user_metadata } = await metadataResponse.json();
        return user_metadata;
      } catch (e) {
        console.log(e.message);
      }
    };
    getUserMetadata().then((result) => {
      if (isAuthenticated && result && result.initiative.split(",").indexOf("COPA") !== -1) {
        setHasCOPAAccess(true);
      } else {
        setHasCOPAAccess(false);
      }
    });
  }, [getAccessTokenSilently, user?.sub, isAuthenticated]);


  const htmlFormatter = (cell) => {
    return (
      <div
        dangerouslySetInnerHTML={
          cell != null ? { __html: `<p>${cell}</p>` } : { __html: `<p></p>` }
        }
      />
    );
  };

  const columns = [
    {
      dataField: "standards",
      text: "Oncology Standard",
      sort: true,
    },
    {
      dataField: "download",
      text: "Download",
      formatter: htmlFormatter,
    },
    {
      dataField: "explanation",
      text: "Explanation",
    },
    {
      dataField: "_id",
      text: "Id",
      hidden: true
    },
  ];

  return items && items !== undefined && items !== "" ? (
    <div className="page-wrapper">

      <div>
        {!hasCOPAAccess ?
          <div>
            <PostTeaser />
            <div id="noAuthCan">
              <SubHeaderWrapper className="my-6">
                <Container>
                  <Row className="mb-6 align-items-center">
                    <Col lg="6">
                      <img
                        src="https://mycoa.coapharmacy.com/static/media/comment-letters-featured.3ef2eba7e6e677705d73.jpg"
                        alt="featured"
                        width="100%"
                      />
                    </Col>
                    <Col lg="6" className="mt-4 mt-lg-0">
                      <h2>ACHC Oncology Distinction</h2>
                      <p>
                        ACHC and COPA have successfully completed an oncology
                        accreditation set of standards specific to pharmacies that
                        dispense oral oncolytics.&nbsp; Pharmacies will still have
                        to first achieve&nbsp;
                        <a
                          href="https://coapharmacy.com/achc-specialty-accreditation/"
                          target="_self"
                        >
                          ACHC pharmacy accreditation
                        </a>
                        &nbsp;prior to attaining these oncology standards.&nbsp;
                        <a
                          href="http://www.achc.org/"
                          target="_self"
                          rel="noopener"
                        >
                          ACHC
                        </a>
                        &nbsp;and COPA have partnered to provide members with a
                        customized suite of specialty pharmacy accreditation
                        offerings, including discounts on educational resources
                        and accreditation programs. ACHC was the first accrediting
                        body to offer specialty pharmacy accreditation.
                      </p>
                    </Col>
                  </Row>
                </Container>
              </SubHeaderWrapper>

              <CardsSection className="cards mb-6">
                <Container>
                  <div className="cards-container ">
                    <Card
                      className="py-6 px-3 "
                      style={{ backgroundColor: "#9E9E9E" }}
                    >
                      <h4 className="text-center mb-4">
                        ACHC | COPA Partner Website
                      </h4>
                      <div className="text-center">
                        <a href="https://cc.achc.org/copa" target="_self">
                          <Button outline color="light">
                            VISIT HERE
                          </Button>
                        </a>
                      </div>
                    </Card>

                    <Card
                      className="py-6 px-3 "
                      style={{ backgroundColor: "#133D8D" }}
                    >
                      <h4 className="text-center mb-4">Partnership Pricing</h4>
                      <div className="text-center">
                        <a
                          href="https://coapharmacy.com/wp-content/uploads/2022/10/COPA_Partnership-Flyer.pdf"
                          target="_blank"
                        >
                          <Button outline color="light">
                            SEE PRICING
                          </Button>
                        </a>
                      </div>
                    </Card>

                    <Card
                      className="py-6 px-3 "
                      style={{ backgroundColor: "#4180BE" }}
                    >
                      <h4 className="text-center mb-4">Oncology Standards</h4>
                      <div className="text-center">
                        <a
                          href="https://coapharmacy.com/wp-content/uploads/2022/12/ONC-SRXONLY_2022-10-06.pdf"
                          target="_blank"
                        >
                          <Button outline color="light">
                            DOWNLOAD THE PDF
                          </Button>
                        </a>
                      </div>
                    </Card>
                  </div>
                </Container>
              </CardsSection>
            </div>
          </div>
          :
          <div id="authCan">
            <SubHeaderWrapper className="my-6">
              <Container>
                <Row className="mb-6 align-items-center">
                  <Col lg="6">
                    <img
                      src="https://mycoa.coapharmacy.com/static/media/comment-letters-featured.3ef2eba7e6e677705d73.jpg"
                      alt="featured"
                      width="100%"
                    />
                  </Col>
                  <Col lg="6" className="mt-4 mt-lg-0">
                    <h2>ACHC Oncology Distinction</h2>
                    <p>
                      ACHC and COPA have successfully completed an oncology
                      accreditation set of standards specific to pharmacies that
                      dispense oral oncolytics.&nbsp; Pharmacies will still have
                      to first achieve&nbsp;
                      <a
                        href="https://coapharmacy.com/achc-specialty-accreditation/"
                        target="_self"
                      >
                        ACHC pharmacy accreditation
                      </a>
                      &nbsp;prior to attaining these oncology standards.&nbsp;
                      <a
                        href="http://www.achc.org/"
                        target="_self"
                        rel="noopener"
                      >
                        ACHC
                      </a>
                      &nbsp;and COPA have partnered to provide members with a
                      customized suite of specialty pharmacy accreditation
                      offerings, including discounts on educational resources
                      and accreditation programs. ACHC was the first accrediting
                      body to offer specialty pharmacy accreditation.
                    </p>
                  </Col>
                </Row>
              </Container>
            </SubHeaderWrapper>

            <CardsSection className="cards mb-6">
              <Container>
                <div className="cards-container ">
                  <Card
                    className="py-6 px-3 "
                    style={{ backgroundColor: "#9E9E9E" }}
                  >
                    <h4 className="text-center mb-4">
                      ACHC | COPA Partner Website
                    </h4>
                    <div className="text-center">
                      <a href="https://cc.achc.org/copa" target="_self">
                        <Button outline color="light">
                          VISIT HERE
                        </Button>
                      </a>
                    </div>
                  </Card>

                  <Card
                    className="py-6 px-3 "
                    style={{ backgroundColor: "#133D8D" }}
                  >
                    <h4 className="text-center mb-4">Partnership Pricing</h4>
                    <div className="text-center">
                      <a
                        href="https://coapharmacy.com/wp-content/uploads/2022/10/COPA_Partnership-Flyer.pdf"
                        target="_blank"
                      >
                        <Button outline color="light">
                          SEE PRICING
                        </Button>
                      </a>
                    </div>
                  </Card>

                  <Card
                    className="py-6 px-3 "
                    style={{ backgroundColor: "#4180BE" }}
                  >
                    <h4 className="text-center mb-4">Oncology Standards</h4>
                    <div className="text-center">
                      <a
                        href="https://coapharmacy.com/wp-content/uploads/2022/12/ONC-SRXONLY_2022-10-06.pdf"
                        target="_blank"
                      >
                        <Button outline color="light">
                          DOWNLOAD THE PDF
                        </Button>
                      </a>
                    </div>
                  </Card>
                </div>
              </Container>
            </CardsSection>

            <ACHCTable className="mb-5">
              <Container>
                <h2 className="mb-5">ACHC Oncology Distinction Standards</h2>
                <BootstrapTable
                  bootstrap4
                  keyField="_id"
                  data={items}
                  columns={columns}
                // pagination={paginationFactory({ sizePerPage: 15 })}
                />
              </Container>
            </ACHCTable>

            <DiscountSection image="https://coapharmacy.com/wp-content/uploads/2022/03/ACHC-Banner-Background-with-text.png">
              <div className="left">
                <img
                  src="https://coapharmacy.com/wp-content/uploads/2022/03/ACHC-Banner-Background-with-text.png"
                  alt="discount"
                />
              </div>
              <div className="right pt-5 pb-5 px-3">
                <img
                  src="https://coapharmacy.com/wp-content/uploads/2022/03/achc-logo-200x200.png"
                  alt="achc"
                  width="75px"
                />
                <div className="discount-code">COPA DISCOUNT CODE</div>
                <img
                  src="https://coapharmacy.com/wp-content/uploads/2022/03/achc-code-2-600x251.png"
                  alt="coa586"
                  className="coa586"
                />

                <h4 className="mt-4">
                  <span>$2,000 OFF</span> SRX/SRX ONLY ACCREDITATION
                </h4>
                <h4>
                  <span>$50 OFF</span> WORKSHOP OR WORKBOOK
                </h4>

                <h6 className="mt-4">
                  <em>
                    $2,000 discount will be applied upon final payment and only
                    one discount is applicable per company for accreditation.
                    $50 discount may be used for any COPA member attending an
                    ACHC workshop or any member purchasing the ACHC Guide to
                    Success digital workbook.
                  </em>
                </h6>
              </div>
            </DiscountSection>
          </div>
        }
      </div>

    </div>
  ) : (
    <p>...</p>
  );
};

export default Oncology;

export const CardsSection = styled.section`
  h4 {
    font-weight: 800;
    color: #fff;
    font-size: 30px;
  }

  .card {
    background-color: transparent;
    border: unset;
    box-shadow: unset;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .col {
    border-radius: 0.25rem;
    overflow: hidden;
    position: relative;
  }

  .cards-container {
    display: flex;
    flex-wrap: nowrap;
    gap: 15px;

    & > div {
      flex: 1 1 33.333333%;
    }

    @media (max-width: 992px) {
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      & > div {
        flex: 1 1 50%;
        max-width: calc(50% - 10px);
        align-self: stretch;
      }
    }

    @media (max-width: 768px) {
      & > div {
        max-width: 100%;
      }
    }
  }
`;

export const DiscountSection = styled.section`
  display: flex;
  background-color: #002238;

  .left {
    width: 40%;
    background-image: url(${(props) => props.image});
    background-size: cover;
    background-position: top center;
    background-color: #002238;

    img {
      visibility: hidden;
      width: 100%;
    }
  }

  .right {
    width: 60%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #002238;

    .discount-code {
      color: #133d8d;
      font-size: 22px;
      margin-top: 30px;
    }

    .coa586 {
      width: 100%;
      max-width: 425px;
    }

    h4 {
      color: #fff;
      font-size: 20px;
      font-weight: 400;
      span {
        font-weight: 700;
      }
    }

    h6 {
      color: #fff;
      font-size: 14px;
      max-width: 533px;
      margin: 0px auto;
    }
  }

  @media (max-width: 1300px) {
    .left {
      width: 60%;
    }

    .right {
      width: 40%;
    }
  }

  @media (max-width: 1000px) {
    flex-direction: column;
    .left {
      width: 100%;
      background-color: #17496d;
    }

    .right {
      width: 100%;
    }
  }
`;

export const ACHCTable = styled.section`
  .react-bootstrap-table {
    overflow-x: auto;

    table {
      min-width: 1024px;

      th {
        &:nth-child(1) {
          width: 200px;
        }

        &:nth-child(2) {
          width: 180px;
        }
      }
    }
  }

  .react-bootstrap-table-pagination-list {
    ul {
      justify-content: flex-end;
    }
  }

  @media (max-width: 768px) {
    .react-bootstrap-table-pagination {
      text-align: center;

      .react-bootstrap-table-pagination-list {
        margin-top: 20px;
        ul {
          justify-content: center;
        }
      }
    }
  }
`;

import React from "react";
import { Container } from "reactstrap";
import styled from "styled-components";

// images
import RestrictedArea from "../../assets/images/restricted-area.png";

const AccessDenied = () => {
  return (
    <AccessDeniedWrapper className="page-wrapper my-6">
      <div className="wrapper">
        <Container>
          <div className="text-center">
            <img
              src={RestrictedArea}
              width="100"
              alt="restriced access image"
            />
          </div>
          <h2 className="text-center mb-3 mt-4">Access Denied!</h2>
          <p className="text-center description mb-0">
            You are not authorized to access this page.
          </p>
        </Container>
      </div>
    </AccessDeniedWrapper>
  );
};

export default AccessDenied;

export const AccessDeniedWrapper = styled.section`
  position: relative;
  min-height: calc(100vh - 310px);
  display: flex;
  align-items: center;
  justify-content: center;

  .description {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
  }
`;

import React, { useState } from "react";
import { Col, Row, Card, FormGroup, Label, Button } from "reactstrap";
import { Link } from "react-router-dom";
import PlaceHolder from "../../assets/images/placeholder-image.jpg";
import styled from "styled-components";

export const Login = () => {
  return (
    <LoginWrapper className="register-page p-3  p-sm-5">
      <Row className="">
        <Col
          xl="8"
          lg="7"
          sm="12"
          md="7"
          className="register-left pr-md-5 mb-3 mb-md-0"
        >
          <h2 className="mb-3 text-primary">Welcome Back!</h2>

          <h5 className="mb-3">Updates from our Initiatives.</h5>
          <Row className="mb-2 initiative-card-wrapper pb-3">
            <Col md="6" lg="3" className="mb-4">
              <Card>
                <img src={PlaceHolder} alt="placeholder" />
              </Card>
            </Col>
            <Col md="6" lg="3" className="mb-4">
              <Card>
                <img src={PlaceHolder} alt="placeholder" />
              </Card>
            </Col>
            <Col md="6" lg="3" className="mb-4">
              <Card>
                <img src={PlaceHolder} alt="placeholder" />
              </Card>
            </Col>
            <Col md="6" lg="3" className="mb-4">
              <Card>
                <img src={PlaceHolder} alt="placeholder" />
              </Card>
            </Col>
          </Row>

          <h5 className="mb-3">
            New Videos, Downloadables, Listservers & more!
          </h5>
          <Row className="initiative-card-wrapper ">
            <Col md="6" lg="3" className="mb-4">
              <Card>
                <img src={PlaceHolder} alt="placeholder" />
              </Card>
            </Col>
            <Col md="6" lg="3" className="mb-4">
              <Card>
                <img src={PlaceHolder} alt="placeholder" />
              </Card>
            </Col>
            <Col md="6" lg="3" className="mb-4">
              <Card>
                <img src={PlaceHolder} alt="placeholder" />
              </Card>
            </Col>
            <Col md="6" lg="3" className="mb-4">
              <Card>
                <img src={PlaceHolder} alt="placeholder" />
              </Card>
            </Col>
          </Row>
        </Col>
        <Col
          className="d-flex align-items-center "
          xl="4"
          lg="5"
          md="5"
          sm="12"
        >
          <div className="w-100 register-right">
            <h4 className="mb-3" style={{ color: "#000", fontWeight: "600" }}>
              Login in
            </h4>

            <Button color="secondary mt-1" block>
              <h4
                className="mb-0 text-white text-inherit"
                style={{ fontSize: "18px", textTransform: "initial" }}
              >
                Sign into myCOA
              </h4>
            </Button>
            <div className=" mt-2">
              <div
                className="d-flex flex-wrap justify-content-center"
                style={{ fontWeight: "500" }}
              >
                New here? &nbsp;
                <Link
                  to="/register"
                  className=""
                  style={{ textDecoration: "underline" }}
                >
                  <div className=" d-sm-block">Create a myCOA Account</div>
                </Link>
              </div>
            </div>

            <hr />
            <small className="text-center d-block mx-1">
              COA collects and uses personal data in accordance with our{" "}
              <Link to="#">Privacy Policy</Link>. By Login, you aggree to our{" "}
              <Link to="#">Terms and Conditions</Link>
            </small>
          </div>
        </Col>
      </Row>
    </LoginWrapper>
  );
};

export default Login;

export const LoginWrapper = styled.div`
  /* background-image: linear-gradient(80deg, #a4b9ff 0%, #ffd4da 100%); */
  min-height: calc(100vh - 184px);
  background-color: #fff;
  .register-left {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .initiative-card-wrapper {
      .card {
        border: 1px solid #d2d2d2;
        box-shadow: unset;

        img {
          overflow: hidden;
          position: relative;
          border-radius: 0.428rem;
        }
      }
    }

    @media (min-width: 1200px) {
      padding-left: 150px !important;
    }

    h2 {
      font-size: 30px;
      color: var(--coa-primary);
      font-weight: 700;
      width: 100%;
    }

    h4 {
      color: #000;
      width: 100%;
    }

    @media (max-width: 992px) {
      margin-top: 0px;
    }
  }

  .register-right {
    background-color: #fff;
    padding: 20px 20px;
    border-radius: 0.42rem;
    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.2);

    a {
      text-decoration: underline;
    }
  }

  .circletop1 {
    position: absolute;
    width: 200px;
    top: 2vw;
    left: -6vw;
  }

  .circletop2 {
    position: absolute;
    width: 163px;
    left: 66%;
    top: 9%;
  }

  .circleright {
    position: absolute;
    right: -42px;
    width: 72px;
    top: 37%;
    transform: translateY(-50%);
  }

  .circlebottombottom {
    position: absolute;
    bottom: -3px;
    left: 65%;
    width: 84px;
  }

  .circlebottomleft {
    position: absolute;
    width: 120px;
    bottom: 11%;
    left: -51px;
  }

  @media (max-width: 992px) {
    .circletop1 {
      display: none;
    }
  }
`;

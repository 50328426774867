// ** Redux, Thunk & Root Reducer Imports
import thunk from 'redux-thunk'
import createDebounce from 'redux-debounced'
import { createStore, applyMiddleware, compose } from 'redux'

// Import Root Reducer
import RootReducer from './Reducer/RootReducer'

// logger
// import logger from 'redux-logger';

// ** init middleware
const middlewares = [thunk, createDebounce() ]
// const middlewares = [thunk, createDebounce(), logger ]

// ** Dev Tools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose


// Create Store
const Store = createStore(RootReducer, {}, composeEnhancers(applyMiddleware(...middlewares)))


export { Store }
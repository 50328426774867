const menuItems = [
  {
    title: "COA INITIATIVES",
    link: "#",
    rel: "nofollow",
    submenu: [
      {
        title: "COA Administrators’ Network (CAN)",
        link: "https://communityoncology.org/coa-initatives/can/",
        target: "_self",
      },
      {
        title: "Community Oncology Pharmacy Association (COPA)",
        link: "https://communityoncology.org/coa-initatives/copa/",
        target: "_self",
      },
      {
        title: "COA Patient Advocacy Network (CPAN)",
        link: "https://communityoncology.org/coa-initatives/cpan/",
        target: "_self",
      },
      {
        title: "COA Fellows Initiative",
        link: "https://communityoncology.org/coa-initatives/fellows/",
        target: "_self",
      },

      {
        title: "Enhancing Oncology Model Support Network (EOM)",
        link: "https://communityoncology.org/coa-initatives/eom/",
        target: "_self",
      },
      {
        title: "National Cancer Treatment Alliance (NCTA)",
        link: "https://communityoncology.org/coa-initatives/ncta/",
        target: "_self",
      },
      {
        title: "Oncology Medical Home (OMH)",
        link: "https://communityoncology.org/coa-initatives/omh/",
        target: "_self",
      },
    ],
  },
  {
    title: "EVENTS",
    link: "#",
    rel: "nofollow",
    submenu: [
      {
        title: "Community Oncology Conference",
        link: "https://mycoa.communityoncology.org/events/annual-conferences",
        target: "_self",
      },
      {
        title: "Payer Exchange Summit",
        link: "https://mycoa.communityoncology.org/events/payer-exchange-summits",
        target: "_self",
      },
      {
        title: "State of Community Oncology Briefing",
        link: "https://mycoa.communityoncology.org/events/state-of-community-oncology-briefings",
        target: "_self",
      },
      {
        title: "Advocacy Chats",
        link: "https://coaadvocacy.org/events/advocacy-chats/",
        target: "_self",
      },
      {
        title: "Advocacy Summit",
        link: "https://mycoa.communityoncology.org/events/advocacy-summits",
        target: "_self",
      },
      {
        title: "Partner Events",
        link: "https://mycoa.communityoncology.org/events/partner-events/",
        target: "_self",
      },
      {
        title: "Webinars",
        link: "https://mycoa.communityoncology.org/events/webinars/",
        target: "_self",
      },
      {
        title: "All COA Events",
        link: "https://mycoa.communityoncology.org/events/",
        target: "_self",
      },
    ],
  },
  {
    title: "GET INVOLVED",
    link: "#",
    rel: "nofollow",
    submenu: [
      {
        title: "Membership Benefits",
        link: "https://communityoncology.org/membership",
        target: "_self",
      },
      {
        title: "Join Now",
        link: "https://mycoa.io/join",
        target: "_self",
      },
    ],
  },
];

export default menuItems;
